<ion-row class="ion-align-items-center" [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col [sizeXs]="sizeXS" [sizeMd]="sizeMD" [offsetMd]="offsetMD">
    <ion-card appLockIcon [isLock]="isLock" [style.height]="height">
      <ion-card-header>
        <ion-card-title>{{ title }} <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon></ion-card-title>
      </ion-card-header>
      <ion-card-content class="card-content-text-large ion-text-center" appLockBlur [isLock]="isLock">
        {{ numberData }}<br />
        <label [ngStyle]="labelStyle">
          <ion-icon *ngIf="labelIcon" [name]="labelIcon" [style]="iconStyle" class="icon-vertical-center"></ion-icon>
          {{ label }}
        </label>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
