import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { BillboardDependency } from '../enum/billboard-dependency.enum';
import { EventDependency } from '../enum/event-dependency.enum';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { BillboardDataService } from '../services/billboard-data.service';
import { EventDataService } from '../services/event-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { LoadingService } from '../services/loading.service';
import { ViewPeriodService } from '../services/view-period.service';
import { Billboard } from './billboard';
import { Event } from './event';
import { SelectableDataName, SelectableData } from './selectableData';
import { InteractableType } from './trafficInteractable';
import { GlobalUiService } from '../services/global-ui.service';
import { environment } from 'src/environments/environment';

export type BaseDataServiceInstance = GraphDataService | EventDataService | BillboardDataService;
export type BaseDataDependency = GraphDependency | EventDependency | BillboardDependency;

export class BaseGraphData {
  public readonly MAX_RETRY_FETCH: number;
  public readonly availableDependency: Set<BaseDataDependency>;
  public readonly subscription: Subscription;
  public readonly graphDependencyToLoader: { [dependency: string]: (date: moment.Moment, instance?: BaseDataServiceInstance) => Promise<void> };
  public readonly retryCounter: { [dependency: string]: number };
  public readonly instanceDataService: BaseDataServiceInstance;
  public readonly viewPeriodService: ViewPeriodService;
  public readonly loadingService: LoadingService;
  public readonly globalUiService: GlobalUiService;
  public comparingBuildingState$: BehaviorSubject<{ [buildingName: string]: boolean }>;
  public comparingFloorState$: BehaviorSubject<{ [floorName: string]: boolean }>;
  public comparingZoneState$: BehaviorSubject<{ [zoneName: string]: boolean }>;
  public isDisplayClickableInfo$: BehaviorSubject<boolean>;
  public billboardList$: BehaviorSubject<Billboard[]>;
  /**
   * Name of the floor of which is selected
   *
   * @example ALL, GROUND
   */
  /** @deprecated use selectedDirectory instead in newer version (> 4.9.1) */
  public selectedFloorName$: BehaviorSubject<string>;
  public selectedDirectory$: BehaviorSubject<{ building: string; floor: string; zone: string }>;
  public selectedLevel$: BehaviorSubject<'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA'>;
  /**
   * Selected Interactable
   *
   * @example null, {name: c_0_0_G1A, type: 'gate', object:...}
   */
  public selectedInteractable$: BehaviorSubject<InteractableType>;
  public selectedPackageName$: BehaviorSubject<string>;
  public selectedFilteredBillboard$: BehaviorSubject<{ location: string[]; package: string[]; type: string[] }>;
  // public isDisplayAverageValue$: BehaviorSubject<boolean>;
  public isDisplayAverageValue$: Map<string, BehaviorSubject<boolean>>;
  public selectedStoreName$ = new BehaviorSubject<string>(null);

  //#region event handle data
  selectedInteractableEvent$: BehaviorSubject<Event> = new BehaviorSubject(null);
  //#endregion event handle data

  constructor(
    graphDependencyToLoader: { [dependency: string]: (date: moment.Moment, instance?: BaseDataServiceInstance) => Promise<void> },
    viewPeriodService: ViewPeriodService,
    loadingService: LoadingService,
    instanceDataService: BaseDataServiceInstance,
    globalUiService: GlobalUiService,
  ) {
    this.graphDependencyToLoader = graphDependencyToLoader;
    this.subscription = new Subscription();
    this.availableDependency = new Set<BaseDataDependency>();
    this.isDisplayAverageValue$ = new Map();
    this.MAX_RETRY_FETCH = 30;
    this.globalUiService = globalUiService;
    this.viewPeriodService = viewPeriodService;
    this.loadingService = loadingService;
    this.instanceDataService = instanceDataService;
    this.retryCounter = {};
    this.comparingBuildingState$ = new BehaviorSubject<{ [buildingName: string]: boolean }>({});
    this.comparingFloorState$ = new BehaviorSubject<{ [floorName: string]: boolean }>({});
    this.comparingZoneState$ = new BehaviorSubject<{ [zoneName: string]: boolean }>({});
    this.isDisplayClickableInfo$ = new BehaviorSubject<boolean>(false);
    this.billboardList$ = new BehaviorSubject<Billboard[]>(null);
    this.selectedFloorName$ = new BehaviorSubject<string>(null);
    this.selectedDirectory$ = new BehaviorSubject<{ building: string; floor: string; zone: string }>(null);
    this.selectedLevel$ = new BehaviorSubject<'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'AREA'>(null);
    this.selectedInteractable$ = new BehaviorSubject<InteractableType>(null);
    this.selectedPackageName$ = new BehaviorSubject<string>(null);
    this.selectedFilteredBillboard$ = new BehaviorSubject<{ location: string[]; package: string[]; type: string[] }>({ location: [], package: ['all'], type: [] });
    this.availableDependency.forEach((baseDataDependency: BaseDataDependency) => {
      this.retryCounter[baseDataDependency] = 0;
      this.setIsDisplayAverageValueForGraph(baseDataDependency, false);
    });
  }

  setSubscribeSelectedDate() {
    this.subscription.add(this.viewPeriodService.subscribeSelectedDate(newDate => {
      this.availableDependency.forEach((baseDataDependency: BaseDataDependency) => {
        this.loadCorespondDependency(baseDataDependency, newDate.date.clone());
      });
    }));
  }

  setSelectedStoreChange() {
    this.subscription.add(this.selectedStoreName$.subscribe(_ => {
      this.availableDependency.forEach((baseDataDependency: BaseDataDependency) => {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate.clone());
      });
    }))
  }

  setSubscribeToIsDisplayAverageValue(baseDataDependency: BaseDataDependency): void {
    this.subscription.add(this.getIsDisplayAverageValueForGraph(baseDataDependency).subscribe((updatedValue) => {
      if (!environment.production) {
        console.log(`isDisplayAverageValue$ for graph ${baseDataDependency} has been updated to:`, updatedValue);
      }
      // Perform any additional actions here, such as updating the UI or applying filters
      this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
    }));
  }

  setIsDisplayAverageValueForGraph(baseDataDependency: BaseDataDependency, value: boolean): void {
    if (this.isDisplayAverageValue$.has(baseDataDependency)) {
      this.isDisplayAverageValue$.get(baseDataDependency).next(value);
    } else {
      this.isDisplayAverageValue$.set(baseDataDependency, new BehaviorSubject<boolean>(value));
    }
  }

  reload3dModel() {
    this.loadCorespondDependency(GraphDependency.MAP_MODEL3D_INFO, this.viewPeriodService.selectedDate);
  }

  private async loadCorespondDependency(baseDataDependency: BaseDataDependency, date: moment.Moment, instanceDataService: BaseDataServiceInstance = this.instanceDataService) {
    if (baseDataDependency === GraphDependency.DYNAMIC) { throw Error('ILLEGAL DYNAMIC depedencies'); }
    const loaderFunction = this.graphDependencyToLoader[baseDataDependency];
    if (!loaderFunction) { return; }
    this.loadingService.startLoading(baseDataDependency);
    for (; this.retryCounter[baseDataDependency] < this.MAX_RETRY_FETCH; this.retryCounter[baseDataDependency]++) {
      try {
        await loaderFunction(date, instanceDataService);
        this.retryCounter[baseDataDependency] = 0;
        this.loadingService.stopLoading(baseDataDependency);
        return;
      } catch (err) {
        console.log('Retry loading', baseDataDependency, this.retryCounter[baseDataDependency], 'times', err);
      }
    }
    console.error('Give up loading', baseDataDependency);
    this.loadingService.stopLoading(baseDataDependency);
  }

  addDependency(baseDataDependencies: BaseDataDependency | BaseDataDependency[]) {
    if (Array.isArray(baseDataDependencies)) {
      baseDataDependencies.forEach(baseDataDependency => this.processAddDepedency(baseDataDependency));
    } else {
      this.processAddDepedency(baseDataDependencies);
    }
  }

  private processAddDepedency(baseDataDependency: BaseDataDependency) {
    if (!this.isDisplayAverageValue$.has(baseDataDependency)) {
      this.setIsDisplayAverageValueForGraph(baseDataDependency, false);
    }
    if (!this.availableDependency.has(baseDataDependency)) {
      const graphDataServiceInstance = this.instanceDataService as GraphDataService;
      // only load data when this dependency is not yet load
      this.availableDependency.add(baseDataDependency);
      this.retryCounter[baseDataDependency] = 0;
      if (baseDataDependency === EventDependency.EVENT_TRAFFIC) {
        this.subscription.add(this.selectedInteractableEvent$.subscribe((selectedInteractableEvent) => {
          if (!selectedInteractableEvent) { return; }
          this.loadCorespondDependency(EventDependency.EVENT_TRAFFIC, moment(selectedInteractableEvent.end_date));
        }));
      } else if (baseDataDependency === EventDependency.EVENT_TRAFFIC_HOUR) {
        this.subscription.add(this.selectedInteractableEvent$.subscribe((selectedInteractableEvent) => {
          if (!selectedInteractableEvent) { return; }
          this.loadCorespondDependency(EventDependency.EVENT_TRAFFIC_HOUR, moment(selectedInteractableEvent.end_date));
        }));
      } else if (baseDataDependency === GraphDependency.VISITOR_PROFILE) {
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVisitorProfileData$, graphDataServiceInstance.selectedVisitorProfile$]).subscribe(([unfilteredVisitorProfileData, selectedProfile]) => {
          if (!unfilteredVisitorProfileData || !selectedProfile) { return; }
          graphDataServiceInstance.deriveSelectedVisitorProfileData(unfilteredVisitorProfileData, selectedProfile);
        }));
      } else if (baseDataDependency === GraphDependency.PURCHASE_RATE_PROFILE) {
        this.subscription.add(graphDataServiceInstance.selectedVisitorProfile$.subscribe(selectedVisitorProfile => {
          if (!selectedVisitorProfile) { return; }
          this.loadCorespondDependency(GraphDependency.PURCHASE_RATE_PROFILE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.AVERAGE_TIME_SPENT_PROFILE) {
        this.subscription.add(graphDataServiceInstance.selectedVisitorProfile$.subscribe(selectedVisitorProfile => {
          if (!selectedVisitorProfile) { return; }
          this.loadCorespondDependency(GraphDependency.AVERAGE_TIME_SPENT_PROFILE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.NET_SHOPPING_TIME_PROFILE) {
        this.subscription.add(graphDataServiceInstance.selectedVisitorProfile$.subscribe(selectedVisitorProfile => {
          if (!selectedVisitorProfile) { return; }
          this.loadCorespondDependency(GraphDependency.NET_SHOPPING_TIME_PROFILE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.STORE_VISITOR_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.STORE_VISITOR_PROFILE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredStoreVisitorProfileData$, graphDataServiceInstance.selectedStoreVisitorProfile$]).subscribe(([unfilteredStoreVisitorProfileData, selectedProfile]) => {
          if (!unfilteredStoreVisitorProfileData || !selectedProfile) { return; }
          graphDataServiceInstance.deriveSelectedStoreVisitorProfileData(unfilteredStoreVisitorProfileData, selectedProfile);
        }));
      } else if (baseDataDependency === GraphDependency.ENTRANCE_EXIT_STORE_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.ENTRANCE_EXIT_STORE_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.STORE_PROXIMITY_TRAFFIC) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.STORE_PROXIMITY_TRAFFIC, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.PREDICTION_STORE_PROXIMITY_TRAFFIC) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.PREDICTION_STORE_PROXIMITY_TRAFFIC, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.ZONE_VISITOR_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.ZONE_VISITOR_PROFILE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(this.selectedDirectory$.subscribe((selectedDirectory) => {
          if (graphDataServiceInstance.globalUiService.currentPage === 'Traffic' || !selectedDirectory || selectedDirectory.floor !== '' || selectedDirectory.zone !== '') {
            return;
          }
          this.loadCorespondDependency(GraphDependency.ZONE_VISITOR_PROFILE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VISITOR_PROFILE_TWO_CROSS) {
        this.subscription.add(graphDataServiceInstance.selectedVisitorProfile$.subscribe((selectedVisitorProfile) => {
          if (!selectedVisitorProfile) { return; }
          this.loadCorespondDependency(GraphDependency.VISITOR_PROFILE_TWO_CROSS, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_COUNT) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_COUNT, this.viewPeriodService.selectedDate);
        }));

      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_UNIQUE_VISITOR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_UNIQUE_VISITOR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_PURCHASING_POWER) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_PURCHASING_POWER, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_CAR_BRAND) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_CAR_BRAND, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_COUNT_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_COUNT_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_MODE_TRANSPORTATION) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_MODE_TRANSPORTATION, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_MODE_TRANSPORTATION_PUBLIC_PRIVATE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_MODE_TRANSPORTATION_PUBLIC_PRIVATE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSiteVehicleProfileData(unfilteredVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileUniqueVisitorData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileUniqueVisitorData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileUniqueVisitorData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSiteVehicleProfileUniqueVisitorData(unfilteredVehicleProfileUniqueVisitorData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfilePublicPrivateData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfilePublicPrivateData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfilePublicPrivateData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSiteVehicleProfilePublicPrivateData(unfilteredVehicleProfilePublicPrivateData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileUniqueVisitorPublicPrivateData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileUniqueVisitorPublicPrivateData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileUniqueVisitorPublicPrivateData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(unfilteredVehicleProfileUniqueVisitorPublicPrivateData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileByHourData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileByHourData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileByHourData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSiteVehicleProfileByHourData(unfilteredVehicleProfileByHourData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.GOAL_ZONE_ENTRANCE_EXIT) {
        this.subscription.add(graphDataServiceInstance.goalStartDate$.subscribe((goalStartDate) => {
          if (!goalStartDate) { return; }
          this.loadCorespondDependency(GraphDependency.GOAL_ZONE_ENTRANCE_EXIT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_STORE_COUNT) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_STORE_COUNT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_STORE_VISITOR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_STORE_VISITOR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_STORE_VISITOR_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_STORE_VISITOR_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_STORE_COUNT_AVG_BY_DAY_TYPE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_STORE_COUNT_AVG_BY_DAY_TYPE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_STORE_VISITOR_AVG_BY_DAY_TYPE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_STORE_VISITOR_AVG_BY_DAY_TYPE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_ADS_VISITOR_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_ADS_VISITOR_PROFILE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.MOCK_ADS_VISITOR_PROFILE_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.MOCK_ADS_VISITOR_PROFILE_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_VISUALIZATION_VIDEOS) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_VISUALIZATION_VIDEOS, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_CONVERSION_RATE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_CONVERSION_RATE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_ENGAGEMENT_MINUTES) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_ENGAGEMENT_MINUTES, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_AVG_ENGAGEMENT_MINUTES) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_AVG_ENGAGEMENT_MINUTES, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_IMPRESSION) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_IMPRESSION, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_IMPRESSION_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_IMPRESSION_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_REACH) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_REACH, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_REACH_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_REACH_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.LIDAR_AREA_VISITOR_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.LIDAR_AREA_VISITOR_PROFILE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_ZONE_SYNERGY_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_ZONE_SYNERGY_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_TIMESPENT_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_TIMESPENT_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_MESSENGER_BRAND_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_MESSENGER_BRAND_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_AVG_DAY_TYPE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_AVG_DAY_TYPE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR) {
        this.subscription.add(graphDataServiceInstance.selectedVisitorProfile$.subscribe((selectedVisitorProfile) => {
          if (!selectedVisitorProfile) { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.STORE_AREA_ENTRANCE_EXIT) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          this.loadCorespondDependency(GraphDependency.STORE_AREA_ENTRANCE_EXIT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.STORE_AREA_ZONE_SYNERGY) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.STORE_AREA_ZONE_SYNERGY, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.STORE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.STORE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.ZONE_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.ZONE_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe(selectedInteractableData => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.predUnfilteredVehicleProfileData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([predUnfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (!predUnfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.derivePredictiveSelectedTrafficSiteVehicleProfileData(predUnfilteredVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR) {
        this.subscription.add(this.selectedInteractable$.subscribe(selectedInteractableData => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.predUnfilteredVehicleProfileUniqueVisitorData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([predUnfilteredVehicleProfileUniqueVisitorData, selectedVehicleProfile]) => {
            if (!predUnfilteredVehicleProfileUniqueVisitorData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.derivePredictiveSelectedTrafficSiteVehicleProfileUniqueVisitorData(predUnfilteredVehicleProfileUniqueVisitorData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.PREDICTIVE_TRAFFIC_SITE_COUNT) {
        this.subscription.add(this.selectedInteractable$.subscribe(selectedInteractableData => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTIVE_TRAFFIC_SITE_COUNT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE) {
        this.subscription.add(graphDataServiceInstance.callTrafficSiteVehicleProfilePrediction$.subscribe(callTrafficSiteProfilePrediction => {
          if (!callTrafficSiteProfilePrediction) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPredictionVehicleProfileData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPredictionVehicleProfileData, selectedVehicleProfile]) => {
            if (!unfilteredPredictionVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedPredictionTrafficSiteVehicleProfileData(unfilteredPredictionVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE) {
        this.subscription.add(graphDataServiceInstance.callTrafficSiteVehicleProfilePublicPrivatePrediction$.subscribe(callTrafficSiteVehicleProfilePublicPrivatePrediction => {
          if (!callTrafficSiteVehicleProfilePublicPrivatePrediction) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPredictionVehicleProfilePublicPrivateData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPredictionVehicleProfilePublicPrivateData, selectedVehicleProfile]) => {
            if (!unfilteredPredictionVehicleProfilePublicPrivateData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedPredictionTrafficSiteVehicleProfilePublicPrivateData(unfilteredPredictionVehicleProfilePublicPrivateData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR) {
        this.subscription.add(graphDataServiceInstance.callTrafficSiteVehicleProfileUniqueVisitorPrediction$.subscribe(callTrafficSiteVehicleProfileUniqueVisitorPrediction => {
          if (!callTrafficSiteVehicleProfileUniqueVisitorPrediction) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPredictionVehicleProfileUniqueVisitorData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPredictionVehicleProfileUniqueVisitorData, selectedVehicleProfile]) => {
            if (!unfilteredPredictionVehicleProfileUniqueVisitorData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedPredictionTrafficSiteVehicleProfileUniqueVisitorData(unfilteredPredictionVehicleProfileUniqueVisitorData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE) {
        this.subscription.add(graphDataServiceInstance.callTrafficSiteVehicleProfileUniqueVisitorPublicPrivatePrediction$.subscribe(callTrafficSiteVehicleProfileUniqueVisitorPublicPrivatePrediction => {
          if (!callTrafficSiteVehicleProfileUniqueVisitorPublicPrivatePrediction) { return; }
          this.loadCorespondDependency(GraphDependency.PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPredictionVehicleProfileUniqueVisitorPublicPrivateData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPredictionVehicleProfileUniqueVisitorPublicPrivateData, selectedVehicleProfile]) => {
            if (!unfilteredPredictionVehicleProfileUniqueVisitorPublicPrivateData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedPredictionTrafficSiteVehicleProfileUniqueVisitorPublicPrivateData(unfilteredPredictionVehicleProfileUniqueVisitorPublicPrivateData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT_BY_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT_BY_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPackageVehicleProfileData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPackageVehicleProfileData, selectedVehicleProfile]) => {
            if (!unfilteredPackageVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSitePackageVehicleProfileData(unfilteredPackageVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_PUBLIC_PRIVATE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_PUBLIC_PRIVATE, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPackageVehicleProfilePublicPrivateData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPackageVehicleProfilePublicPrivateData, selectedVehicleProfile]) => {
            if (!unfilteredPackageVehicleProfilePublicPrivateData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSitePackageVehicleProfilePublicPrivateData(unfilteredPackageVehicleProfilePublicPrivateData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredPackageVehicleProfileByHourData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredPackageVehicleProfileByHourData, selectedVehicleProfile]) => {
            if (!unfilteredPackageVehicleProfileByHourData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedTrafficSitePackageVehicleProfileByHourData(unfilteredPackageVehicleProfileByHourData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE) {
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (selectedVehicleProfile?.vehicle_parking) {
              graphDataServiceInstance.vehicleParkingVehicleProfileLock = 0;
              graphDataServiceInstance.fetchVehicleParkingVehicleProfileData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingVehicleProfileLock, selectedVehicleProfile?.vehicle_parking)
                .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileData(data, selectedVehicleProfile));
            } else {
              if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
              graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileData(unfilteredVehicleProfileData, selectedVehicleProfile);
            }
          }));
        this.subscription.add(this.selectedDirectory$.subscribe((selectedDirectoryData) => {
          if (selectedDirectoryData?.floor !== 'ALL') {
            return;
          }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA) {
        graphDataServiceInstance.vehicleParkingVehicleProfileAllAreaLock = 0;
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileAllAreaData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileAllAreaData(unfilteredVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_AVG_DAY_TYPE) {
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleParkingVehicleProfileAvgDayTypeData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (selectedVehicleProfile?.vehicle_parking) {
              graphDataServiceInstance.vehicleParkingVehicleProfileAvgDayTypeLock = 0;
              graphDataServiceInstance.fetchVehicleParkingVehicleProfileAvgDayTypeData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingVehicleProfileAvgDayTypeLock, selectedVehicleProfile?.vehicle_parking)
                .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileAvgDayTypeData(data, selectedVehicleProfile));
            } else {
              if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
              graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileAvgDayTypeData(unfilteredVehicleProfileData, selectedVehicleProfile);
            }
          }));
        this.subscription.add(this.selectedDirectory$.subscribe((selectedDirectoryData) => {
          if (selectedDirectoryData?.floor !== 'ALL') {
            return;
          }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR) {
        this.subscription.add(this.selectedDirectory$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleProfileByHourData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (selectedVehicleProfile?.vehicle_parking) {
              graphDataServiceInstance.vehicleParkingVehicleProfileByHourLock = 0;
              graphDataServiceInstance.fetchVehicleParkingVehicleProfileByHourData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingVehicleProfileByHourLock, selectedVehicleProfile?.vehicle_parking)
                .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileByHourData(data, selectedVehicleProfile));
            } else {
              if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
              graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileByHourData(unfilteredVehicleProfileData, selectedVehicleProfile);
            }
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleParkingProfileByPinData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileByPinData(unfilteredVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) { return; }
          this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR, this.viewPeriodService.selectedDate);
        }));
        this.subscription.add(combineLatest([graphDataServiceInstance.unfilteredVehicleParkingVehicleProfileByPinByHourData$, graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([unfilteredVehicleProfileData, selectedVehicleProfile]) => {
            if (!unfilteredVehicleProfileData || !selectedVehicleProfile) { return; }
            graphDataServiceInstance.deriveSelectedVehicleParkingVehicleProfileByPinByHourData(unfilteredVehicleProfileData, selectedVehicleProfile);
          }));
      } else if (baseDataDependency === GraphDependency.STORE_AREA_ZONE_TRAFFIC_FLOW) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          if (!selectedInteractableData) {
            return;
          }
          this.loadCorespondDependency(GraphDependency.STORE_AREA_ZONE_TRAFFIC_FLOW, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT) {
        this.subscription.add(graphDataServiceInstance.goalDurationDate$.subscribe((goalDurationDate) => {
          if (!goalDurationDate) { return; }
          this.loadCorespondDependency(GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT_2) {
        this.subscription.add(graphDataServiceInstance.goalDurationDate2$.subscribe((goalDurationDate) => {
          if (!goalDurationDate) { return; }
          this.loadCorespondDependency(GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT_2, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN) {
        this.loadCorespondDependency(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, this.viewPeriodService.selectedDate);
        this.subscription.add(graphDataServiceInstance.baseGraphData.selectedDirectory$.subscribe(selectDirectory => {
          if (selectDirectory?.floor !== 'ALL') { return; }
          this.loadCorespondDependency(GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, this.viewPeriodService.selectedDate);
        }));
        this.setSubscribeToIsDisplayAverageValue(baseDataDependency);
      } else if (baseDataDependency === GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR) {
        this.subscription.add(graphDataServiceInstance.baseGraphData.selectedDirectory$.subscribe(selectDirectory => {
          if (selectDirectory?.floor !== 'ALL') { return; }
          this.loadCorespondDependency(GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.ENTRANCE_EXIT) {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        this.setSubscribeToIsDisplayAverageValue(baseDataDependency);
      } else if (baseDataDependency === GraphDependency.PREDICTION_ENTRANCE_EXIT) {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        this.setSubscribeToIsDisplayAverageValue(baseDataDependency);
      } else if (baseDataDependency === GraphDependency.ENTRANCE_EXIT_HOUR) {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        this.setSubscribeToIsDisplayAverageValue(baseDataDependency);
      } else if (baseDataDependency === GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING) {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        this.setSubscribeToIsDisplayAverageValue(baseDataDependency);
      } else if (baseDataDependency === GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR) {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        this.setSubscribeToIsDisplayAverageValue(baseDataDependency);
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT) {
        this.subscription.add(combineLatest([graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([_selectedVehicleProfile]) => {
            this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
          }));
        this.subscription.add(this.selectedInteractable$.subscribe((_selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_FREQ_VISIT_PROFILE) {
        // this.subscription.add(this.selectedDirectory$.subscribe((selectedInteractableData) => {
        //   // if (!selectedInteractableData) { return; }
        //   this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, this.viewPeriodService.selectedDate);
        // }));
        this.subscription.add(combineLatest([graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([selectedVehicleProfile]) => {
            const vehicleParking = selectedVehicleProfile?.vehicle_parking || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
            graphDataServiceInstance.vehicleParkingProfileFrequencyOfVisitLock = 0;
            graphDataServiceInstance.fetchVehicleParkingFrequencyOfVisitProfileData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingProfileFrequencyOfVisitLock, vehicleParking)
              .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingFrequencyOfVisitProfileData(data, selectedVehicleProfile));
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS_PROFILE) {
        // this.subscription.add(this.selectedDirectory$.subscribe((selectedInteractableData) => {
        //   // if (!selectedInteractableData) { return; }
        //   this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, this.viewPeriodService.selectedDate);
        // }));
        this.subscription.add(combineLatest([graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([selectedVehicleProfile]) => {
            const vehicleParking = selectedVehicleProfile?.vehicle_parking || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
            graphDataServiceInstance.vehicleParkingRepeatedVisitorsProfileLock = 0;
            graphDataServiceInstance.fetchVehicleParkingRepeatedVisitorsProfileData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingRepeatedVisitorsProfileLock, vehicleParking)
              .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingRepeatedVisitorsProfileData(data, selectedVehicleProfile));
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE) {
        // this.subscription.add(this.selectedDirectory$.subscribe((selectedInteractableData) => {
        //   // if (!selectedInteractableData) { return; }
        //   this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, this.viewPeriodService.selectedDate);
        // }));
        this.subscription.add(combineLatest([graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([selectedVehicleProfile]) => {
            const vehicleParking = selectedVehicleProfile?.vehicle_parking || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
            graphDataServiceInstance.vehicleParkingRecencyFrequencyProfileLock = 0;
            graphDataServiceInstance.fetchVehicleParkingRecencyFrequencyProfileData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingRecencyFrequencyProfileLock, vehicleParking)
              .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingRecencyFrequencyProfileData(data, selectedVehicleProfile));
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT) {
        this.subscription.add(combineLatest([graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([_selectedVehicleProfile]) => {
            this.loadCorespondDependency(GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT, this.viewPeriodService.selectedDate);
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_TIMESPENT_PROFILE) {
        this.subscription.add(combineLatest([graphDataServiceInstance.selectedVehicleProfile$])
          .subscribe(([selectedVehicleProfile]) => {
            const vehicleParking = selectedVehicleProfile?.vehicle_parking || graphDataServiceInstance.configDataService.isFeatureEnabled('graph_data')?.vehicle_parking?.default_area_name || 'all';
            graphDataServiceInstance.vehicleParkingTimespentProfileLock = 0;
            graphDataServiceInstance.fetchVehicleParkingTimespentProfileData(this.viewPeriodService.selectedDate, ++graphDataServiceInstance.vehicleParkingTimespentProfileLock, vehicleParking)
              .then(([data, _lockNum]) => graphDataServiceInstance.deriveSelectedVehicleParkingTimespentProfileData(data, selectedVehicleProfile));
          }));
      } else if (baseDataDependency === GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY) {
        this.subscription.add(this.selectedInteractable$.subscribe((selectedInteractableData) => {
          // if (!selectedInteractableData) {
          //   return;
          // }
          this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
        }));
      }
      else {
        this.loadCorespondDependency(baseDataDependency, this.viewPeriodService.selectedDate);
      }
    }
  }

  public getSelectedGraph<T>(name: SelectableDataName, instanceDataService: BaseDataServiceInstance = this.instanceDataService): { dependencies: BaseDataDependency | BaseDataDependency[]; data: BehaviorSubject<T> } {
    const selectorDetail = SelectableData.SelectableDataMapper[name];
    return {
      dependencies: selectorDetail.dependecies,
      data: selectorDetail.data(instanceDataService) as BehaviorSubject<T>,
    };
  }

  getIsDisplayAverageValueForGraph(graphId: string): BehaviorSubject<boolean> {
    return this.isDisplayAverageValue$.get(graphId);
  }

} 
