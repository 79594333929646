<ion-row [ngStyle]="isShow ? { display: 'flex' } : { display: 'none' }">
  <ion-col [sizeXs]="sizeXS" [sizeMd]="sizeMD" [offsetMd]="offsetMD" appLockIcon [isLock]="isLock" [inputLockText]="inputLockText" appDataTbd [isEnableTBD]="isEnableTBD" [lockOpacity]="lockOpacity">
    <ion-card>
      <ion-card-header>
        <ion-card-title>
          {{ title }}
          <ion-icon *ngIf="infoPopover" (click)="infoPopover($event)" class="icon-vertical-center info-icon" name="information-circle-outline"></ion-icon>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content style="margin-top: 0px; padding-top: 5px; margin-bottom: 8px">
        <ion-grid appLockBlur [isLock]="isLock">
          <ion-row [ngStyle]="isShowLegendTitle ? { 'margin-top': '0px' } : { 'margin-top': '0.25rem' }">
            <ion-col size-xs="12" size-md="10" offset-md="1">
              <app-generic-pie-chart [isOverallPage]="true" [data$]="data$" [isShowLegend]="isShowLegend" [customToolTipFuction]="customToolTipFuction"> </app-generic-pie-chart>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
