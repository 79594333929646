import { SelectableDataName } from './../objects/selectableData';
import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { GenericLineChartData } from '../objects/chart';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { Label } from 'ng2-charts';
import { CHART_PRIMARY_COLOR_OPACITY } from '../configs/color';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';

export class SimpleLineResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const datasourceKey = (getAdditionalInput(additionalInput, 'datasourceKey')) as string;
        const postProcessDataKey = (getAdditionalInput(additionalInput, 'postProcessDataKey')) as string;
        graphDataService.baseGraphData.addDependency(GraphDependency[datasourceKey])
        const chartData$ = new BehaviorSubject<GenericLineChartData[]>(null);
        const label$ = new BehaviorSubject<Label[]>(viewPeriodService.DAY_LIST);
        subscription.add(combineLatest(graphDataService[postProcessDataKey])
            .subscribe((linePoints: [number[]]) => {
                if (linePoints.every(p => p === null)) {
                    return;
                }
                const dateTimeLabel = viewPeriodService.DAY_LIST;
                const areaName = graphDataService.baseGraphData.selectedStoreName$.getValue();
                const chartData: GenericLineChartData[] = [{
                    points: linePoints[0].concat(null),
                    prediction: [null],
                    backgroundColor: CHART_PRIMARY_COLOR_OPACITY,
                    toolTipLabel: dateTimeLabel.map(day => `${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[areaName] || areaName},${day}`),
                }]
                chartData$.next(chartData);
                label$.next(viewPeriodService.DAY_LIST);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE.TRAFFIC_TREND;
        comInstance.isLock = this.isLock;
        comInstance.data$ = chartData$;
        comInstance.label$ = label$;
        return comInstance;
    }

}
