import { FemaleUsedCountResolver } from './FemaleUsedCountResolver';
import { StaffOccupancyResolver } from './StaffOccupancyResolver';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { DynamicNumberCardComponent } from '../shared-components/dynamic/dynamic-number-card/dynamic-number-card.component';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { DynamicLineChartSelectableResolver } from './DynamicLineChartSelectableResolver';
import { GraphResolverBase } from './GraphResolverBase';
import { HeadcountNumberCardResolver } from './HeadcountNumberCardResolver';
import { DurationOfVisitTrendResolver } from './DurationOfVisitTrendResolver';
import { DurationOfVisitNumberCardResolver } from './DurationOfVisitNumberCardResolver';
import { PopularTimeGraphResolver } from './PopularTimeGraphResolver';
import { PopularTimeBusiestNumberCardResolver } from './PopularTimeBusiestNumberCardResolver';
import { GenderProfileResolver } from './GenderProfileResolver';
import { EthnicityProfileResolver } from './EthnicityProfileResolver';
import { NonAsianEthnicityProfileResolver } from './NonAsianEthnicityProfileResolver';
import { AsianEthnicityProfileResolver } from './AsianEthnicityProfileResolver';
import { AgeProfileResolver } from './AgeProfileResolver';
import { EstimateTouristResolver } from './EstimateTouristResolver';
import { RepeatedVisitorsResolver } from './RepeatedVisitorsResolver';
import { FrequencyVisitResolver } from './FrequencyVisitResolver';
import { ModeOfTransportResolver } from './ModeOfTransportResolver';
import { VehicleByProvinceResolver } from './VehicleByProvinceResolver';
import { EntranceExitAnalysisResolver } from './EntranceExitAnalysisResolver';
import { EntranceExitPerTwoHourResolver } from './EntranceExitPerTwoHourResolver';
import { DynamicItemDividerComponent } from '../shared-components/dynamic/dynamic-item-divider/dynamic-item-divider.component';
import { HeadcountDividerResolver, PurchaseBehaviorDividerResolver, VisitingBehaviorDividerResolver, NetShoppingHourDividerResolver, StoreVisitorProfileDividerResolver, StoreTenantDividerResolver } from './DividerResolver';
import { VisitorTrafficTrendResolver } from './VisitorTrafficTrendResolver';
import { VisitorTrafficHeadcountNumberCardResolver } from './VisitorTrafficHeadcountNumberCardResolver';
import { VisitorPurchaseRateTrendResolver } from './VisitorPurchaseRateTrendResolver';
import { VisitorPurchaseRateNumberCardResolver } from './VisitorPurchaseRateNumberCardResolver';
import { VisitorDurationOfVisitTrendResolver } from './VisitorDurationOfVisitTrendResolver';
import { VisitorAverageTimeSpentNumberCardResolver } from './VisitorAverageTimeSpentNumberCardResolver';
import { DynamicHeatmapWrapperComponent } from '../shared-components/dynamic/dynamic-heatmap-wrapper/dynamic-heatmap-wrapper.component';
import { TimeVisitHeatmapResolver } from './TimeVisitHeatmapResolver';
import { CustomSegmentationResolver } from './CustomSegmentationResolver';
import { VisitorNetShoppingHourTrendResolver } from './VisitorNetShoppingHourTrendResolver';
import { VisitorNetShoppingHourNumberCardResolver } from './VisitorNetShoppingHourNumberCardResolver';
import { DynamicDualNumberCardComponent } from '../shared-components/dynamic/dynamic-dual-numbercard/dynamic-dual-numbercard.component';
import { PurchaseRateAndAverageTimeSpentNumberCardResolver } from './PurchaseRateAndAverageTimeSpentNumberCardResolver';
import { AverageCongestionNumberCardResolver } from './AverageCongestionNumberCardResolver';
import { DynamicGaugeChartWrapperComponent } from '../shared-components/dynamic/dynamic-gauge-chart-wrapper/dynamic-gauge-chart-wrapper.component';
import { CurrentCustomerGaugeResolver } from './CurrentCustomerGaugeResolver';
import { ParkingLotOccupancyResolver } from './ParkingLotOccupancyResolver';
import { FloorSummaryResolver } from './FloorSummaryResolver';
import { FloorPopularTimeGraphResolver } from './FloorPopularTimeGraphResolver';
import { FloorTrafficTrendResolver } from './FloorTrafficTrendResolver';
import { FloorAvgTimeSpentResolver } from './FloorAvgTimeSpentResolver';
import { FloorNetShoppingHourResolver } from './FloorNetShoppingHourResolver';
import { FloorHeadcountNumberCardResolver } from './FloorHeadcountNumberCardResolver';
import { FloorNetShoppingHourPieResolver } from './FloorNetShoppingHourPieResolver';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { FloorFromToResolver } from './FloorFromToResolver';
import { GateTrafficResolver } from './GateTrafficResolver';
import { GateTimeVisitResolver } from './GateTimeVisitResolver';
import { OverallTrafficTrendResolver } from './OverallTrafficTrendResolver';
import { OverallHeadcountResolver } from './OverallHeadcountResolver';
import { OverallAvgTimeSpentResolver } from './OverallAvgTimeSpentResolver';
import { OverallAvgTimeSpentBarResolver } from './OverallAvgTimeSpentBarResolver';
import { OverallNetShoppingTimeResolver } from './OverallNetShoppingTimeResolver';
import { OverallNetShoppingTimePieResolver } from './OverallNetShoppingTimePieResolver';
import { OverallPopularTimeResolver } from './OverallPopularTimeResolver';
import { OverallTimeVisitResolver } from './OverallTimeVisitResolver';
import { VehicleByProvinceBarResolver } from './VehicleByProvinceBarResolver';
import { DynamicBarChartSelectableResolver } from './DynamicBarChartSelectableResolver';
import { DynamicSankeyWrapperComponent } from '../shared-components/dynamic/dynamic-sankey-wrapper/dynamic-sankey-wrapper.component';
import { StoreVisitorTrafficTrendResolver } from './StoreVisitorTrafficTrendResolver';
import { StoreFootTrafficResolver } from './StoreFootTrafficResovler';
import { StoreTrafficHeadcountNumberCardResolver } from './StoreTrafficHeadcountNumberCardResolver';
import { StoreVisitorDurationOfVisitTrendResolver } from './StoreVisitorDurationOfVisitTrendResolver';
import { StoreVisitorNetShoppingHourTrendResolver } from './StoreVisitorNetShoppingHourTrendResolver';
import { StoreAgeProfileResolver } from './StoreAgeProfileResolver';
import { StoreGenderProfileResolver } from './StoreGenderProfileResolver';
import { StoreEthnicityProfileResolver } from './StoreEthnicityProfileResolver';
import { MallFootTrafficResolver } from './MallFootTrafficResolver';
import { StoreNetShoppingHourNumberCardResolver, StorePurchaseRateAndAverageTimeSpentNumberCardResolver } from './StoreNumberCardResolver';
import { FloorZoneFootTrafficResolver } from './FloorZoneFootTraffic';
import { HeadcountATSNVHNumberCardResolver } from './HeadcountATSNVHNumberCardResolver';
import { MaskCountBHPRNumberCardResolver } from './MaskCountBHPRNumberCardResolver';
import { EntranceExitPerHourResolver } from './EntranceExitPerHourResolver';
import { MaskCountNumberCardResolver } from './MaskCountNumberCardResolver';
import { DynamicProgressNumberCardComponent } from '../shared-components/dynamic/dynamic-progress-number-card/dynamic-progress-number-card.component';
import { TopTenCarBrandResolver } from './TopTenCarBrandResolver';
import { VehiclePurchasingPowerResolver } from './VehiclePurchasingPowerResolver';
import { ModeOfTransportByHourResolver } from './ModeOfTransportByHourResolver';
import { DynamicCustomGoalCardResolver } from './DynamicCustomGoalCardResolver';
import { HighlightNumberCardResolver } from './HighlightNumberCardRevolser';
import { DynamicStackBarChartComponent } from '../shared-components/dynamic/dynamic-stack-bar-chart/dynamic-stack-bar-chart.component';
import { Custom100StackBarChartResolver } from './Custom100StackBarChartResolver';
import { CustomLineChartResolver } from './CustomLineChartResolver';
import { DynamicCustomLineChartComponent } from '../shared-components/dynamic/dynamic-custom-line-chart/dynamic-custom-line-chart.component';
import { CustomBarChartResolver } from './CustomBarChartResolver';
import { DynamicCustomBarChartComponent } from '../shared-components/dynamic/dynamic-custom-bar-chart/dynamic-custom-bar-chart.component';
import { AverageTrafficDayTypeNumberCardResolver } from './AverageTrafficDayTypeNumberCardResolver';
import { DynamicCustomPieChartComponent } from '../shared-components/dynamic/dynamic-custom-pie-chart/dynamic-custom-pie-chart.component';
import { CustomPieChartResolver } from './CustomPieChartResolver';
import { CustomNumberCardResolver } from './CustomNumberCardResolver';
import { CalendarViewComponent } from '../pages/home/general/calendar-view/calendar-view.component';
import { CalendarPerformanceResolver } from './CalendarPerformanceResolver';
import { DynamicDividerSelectorComponent } from '../shared-components/dynamic/dynamic-divider-selector/dynamic-divider-selector.component';
import { DynamicDividerSelectorResolver } from './DynamicDividerSelectorResolver';
import { CustomSankeyChartResolver } from './CustomSankeyChartResolver';
import { AgeProfileByPinResolver } from './AgeProfileByPinResolver';
import { ZoneAgeProfileByPinResolver } from './ZoneAgeProfile';
import { ZoneEntranceExitAnalysisResolver } from './ZoneEntranceExitAnalysisResolver';
import { DynamicCustomHeatmapWrapperComponent } from '../shared-components/dynamic/dynamic-custom-heatmap-wrapper/dynamic-custom-heatmap-wrapper.component';
import { CustomHeatmapChartResolver } from './CustomHeatmapChartResolver';
import { DynamicAgeProfileResolver } from './DynamicAgeProfileResolver';
import { DynamicGenderProfileResolver } from './DynamicGenderProfile';
import { DynamicEchartComponent } from '../shared-components/dynamic/dynamic-echart/dynamic-echart.component';
import { GenericEchartResolver } from './GenericEchartResolver';
import { StaffTrafficDurationResolver } from './StaffTrafficDurationResolver';
import { MaleFemaleTrafficeResolver } from './MaleFemaleTrafficResolver';
import { NumberOfSecurityGuard } from './NumberOfSecurityGuard';
import { MaleFemaleLineChartResolver } from './MaleFemaleLineChartResolver';
import { MaleUsedCountResolver } from './MaleUsedCountResolver';
import { FloorSummaryToiletUsedResolver } from './FloorSummaryToiletUsedResolver';
import { AgeStoreProfileResolver } from './AgeStoreProfileResolver';
import { DemographicProfileBarResolver } from './DemographicProfileBarResolver';
import { EthnicityProfilePurchaseRateResolver } from './EthnicityProfilePurchaseRateResolver';
import { AverageWeekDayWeekEndCountResolver } from './AverageWeekDayWeekEndCountResolver';
import { SimpleLineResolver } from './SimpleLineResolver';
import { SimplePieResolver } from './SimplePieResolver';
import { SimpleNumberCardResolver } from './SimpleNumberCardResolver';
import { SimpleNumberCardComponent } from '../shared-components/dynamic/simple-number-card/simple-number-card.component';
import { SimpleBarResolver } from './SimpleBarResolver';

export type GraphComponent = typeof DynamicLineChartWrapperComponent | typeof DynamicNumberCardComponent | typeof DynamicPieChartWrapperComponent
     | typeof DynamicBarChartWrapperComponent | typeof DynamicItemDividerComponent | typeof DynamicHeatmapWrapperComponent | typeof DynamicDualNumberCardComponent
     | typeof DynamicGaugeChartWrapperComponent | typeof DynamicSankeyWrapperComponent | typeof DynamicProgressNumberCardComponent | typeof DynamicStackBarChartComponent
     | typeof DynamicCustomLineChartComponent | typeof DynamicCustomPieChartComponent | typeof DynamicCustomBarChartComponent | typeof CalendarViewComponent | typeof DynamicDividerSelectorComponent
     | typeof DynamicCustomHeatmapWrapperComponent | typeof DynamicEchartComponent | typeof SimpleNumberCardComponent;
export type GraphComponentInstance = DynamicLineChartWrapperComponent | DynamicNumberCardComponent | DynamicPieChartWrapperComponent | DynamicBarChartWrapperComponent
     | DynamicItemDividerComponent | DynamicHeatmapWrapperComponent | DynamicDualNumberCardComponent | DynamicGaugeChartWrapperComponent | DynamicSankeyWrapperComponent
     | DynamicProgressNumberCardComponent | DynamicStackBarChartComponent | DynamicCustomLineChartComponent | DynamicCustomPieChartComponent | DynamicCustomBarChartComponent
     | CalendarViewComponent | DynamicDividerSelectorComponent | DynamicCustomHeatmapWrapperComponent | DynamicEchartComponent | SimpleNumberCardComponent;

export enum GraphList {
     DYNAMIC_LINE_SELECTABLE = 'DYNAMIC_LINE_SELECTABLE',
     DYNAMIC_BAR_SELECTABLE = 'DYNAMIC_BAR_SELECTABLE',
     HEADCOUNT = 'HEADCOUNT',
     DURATION_VISIT_TREND = 'DURATION_VISIT_TREND',
     DURATION_VISIT_NUMBERCARD = 'DURATION_VISIT_NUMBERCARD',
     POPULAR_TIME_GRAPH = 'POPULAR_TIME_GRAPH',
     POPULAR_TIME_BUSIEST_NUMBER_CARD = 'POPULAR_TIME_BUSIEST_NUMBER_CARD',
     GENDER_PROFILE = 'GENDER_PROFILE',
     ETHNICITY_PROFILE = 'ETHNICITY_PROFILE',
     AGE_PROFILE = 'AGE_PROFILE',
     ZONE_AGE_PROFILE = 'ZONE_AGE_PROFILE',
     AGE_PROFILE_BY_PIN = 'AGE_PROFILE_BY_PIN',
     ESTIMATED_TOURISTS = 'ESTIMATED_TOURISTS',
     CUSTOMER_SEGMENTATION = 'CUSTOMER_SEGMENTATION',
     NON_ASIAN_ETHNICITY_PROFILE = 'NON_ASIAN_ETHNICITY_PROFILE',
     ASIAN_ETHNICITY_PROFILE = 'ASIAN_ETHNICITY_PROFILE',
     REPETATED_VISITORS = 'REPETATED_VISITORS',
     FREQUENCY_VISIT = 'FREQUENCY_VISIT',
     MODE_TRANSPORTATION = 'MODE_TRANSPORTATION',
     VEHICLE_PROVINCE = 'VEHICLE_PROVINCE',
     VEHICLE_PROVINCE_BAR = 'VEHICLE_PROVINCE_BAR',
     ENTRANCE_EXIT_ANALYSIS = 'ENTRANCE_EXIT_ANALYSIS',
     ENTRANCE_EXIT_BIHOURS = 'ENTRANCE_EXIT_BIHOURS',
     HEADCOUNT_DIVIDER = 'HEADCOUNT_DIVIDER',
     PURCHASE_BEHAVIOR_DIVIDER = 'PURCHASE_BEHAVIOR_DIVIDER',
     VISITING_BEHAVIOR_DIVIDER = 'VISITING_BEHAVIOR_DIVIDER',
     NET_SHOPPING_HOUR_DIVIDER = 'NET_SHOPPING_HOUR_DIVIDER',
     VISITOR_TRAFFIC_TREND = 'VISITOR_TRAFFIC_TREND',
     VISITOR_TRAFFIC_VOLUME = 'VISITOR_TRAFFIC_VOLUME',
     VISITOR_PURCHASE_RATE_TREND = 'VISITOR_PURCHASE_RATE_TREND',
     VISITOR_PURCHASE_RATE_NUMBER_CARD = 'VISITOR_PURCHASE_RATE_NUMBER_CARD',
     VISITOR_DURATION_VISIT_TREND = 'VISITOR_DURATION_VISIT_TREND',
     VISITOR_DURATION_VISIT_NUMBER_CARD = 'VISITOR_DURATION_VISIT_NUMBER_CARD',
     VISITOR_NET_SHOPPING_HOUR_TREND = 'VISITOR_NET_SHOPPING_HOUR_TREND',
     VISITOR_NET_SHOPPING_HOUR_NUMBER_CARD = 'VISITOR_NET_SHOPPING_HOUR_NUMBER_CARD',
     TIME_VISIT_HEATMAP = 'TIME_VISIT_HEATMAP',
     PURCHASE_AVERAGE_TIME_SPENT_NUMBER_CARD = 'PURCHASE_AVERAGE_TIME_SPENT_NUMBER_CARD',
     AVERAGE_CONGESTION = 'AVERAGE_CONGESTION',
     CURRENT_CUSTOMER = 'CURRENT_CUSTOMER',
     PARKING_LOT_OCCUPANCY = 'PARKING_LOT_OCCUPANCY',
     FLOOR_SUMMARY = 'FLOOR_SUMMARY',
     FLOOR_POPULAR_TIME = 'FLOOR_POPULAR_TIME',
     FLOOR_TRAFFIC_TREND = 'FLOOR_TRAFFIC_TREND',
     FLOOR_AVG_TIME_SPENT = 'FLOOR_AVG_TIME_SPENT',
     FLOOR_NET_SHOPPING_HOUR = 'FLOOR_NET_SHOPPING_HOUR',
     FLOOR_HEADCOUNT_CARD = 'FLOOR_HEADCOUNT_CARD',
     FLOOR_NET_SHOPPING_HOUR_PIE = 'FLOOR_NET_SHOPPING_HOUR_PIE',
     FLOOR_FROM_TO = 'FLOOR_FROM_TO',
     GATE_TRAFFIC = 'GATE_TRAFFIC',
     GATE_TIME_VISIT = 'GATE_TIME_VISIT',
     OVERALL_TRAFFIC = 'OVERALL_TRAFFIC',
     OVERALL_HEADCOUNT = 'OVERALL_HEADCOUNT',
     OVERALL_AVG_TIME_SPENT = 'OVERALL_AVG_TIME_SPENT',
     OVERALL_AVG_TIME_SPENT_BAR = 'OVERALL_AVG_TIME_SPENT_BAR',
     OVERALL_NET_SHOPPING_HOUR = 'OVERALL_NET_SHOPPING_HOUR',
     OVERALL_NET_SHOPPING_HOUR_PIE = 'OVERALL_NET_SHOPPING_HOUR_PIE',
     OVERALL_POPULAR_TIME = 'OVERALL_POPULAR_TIME',
     OVERALL_POPULAR_TIME_HEATMAP = 'OVERALL_POPULAR_TIME_HEATMAP',
     STORE_VISITOR_PROFILE_TRAFFIC_TREND = 'STORE_VISITOR_PROFILE_TRAFFIC_TREND',
     STORE_FOOT_TRAFFIC = 'STORE_FOOT_TRAFFIC',
     STORE_VISITOR_PROFILE_HEADCOUNT = 'STORE_VISITOR_PROFILE_HEADCOUNT',
     STORE_VISITOR_DURATION_VISIT = 'STORE_VISITOR_DURATION_VISIT',
     STORE_VISITOR_NET_SHOPPING_HOUR = 'STORE_VISITOR_NET_SHOPPING_HOUR',
     STORE_VISITOR_AGE_PROFILE = 'STORE_VISITOR_AGE_PROFILE',
     STORE_VISITOR_PROFILE_DIVIDER = 'STORE_VISITOR_PROFILE_DIVIDER',
     STORE_VISITOR_GENDER_PROFILE = 'STORE_VISITOR_GENDER_PROFILE',
     STORE_VISITOR_ETHNICITY_PROFILE = 'STORE_VISITOR_ETHNICITY_PROFILE',
     STORE_TENANT_DIVIDER = 'STORE_TENANT_DIVIDER',
     MALL_FOOT_TRAFFIC = 'MALL_FOOT_TRAFFIC',
     STORE_SHOPPING_NET_HOUR_CARD = 'STORE_SHOPPING_NET_HOUR_CARD',
     STORE_PURCHASE_RATE_AVG_TIME_CARD = 'STORE_PURCHASE_RATE_AVG_TIME_CARD',
     FLOOR_ZONE_FOOT_TRAFFIC = 'FLOOR_ZONE_FOOT_TRAFFIC',
     HEADCOUNT_ATS_NVH_NUMBER_CARD = 'HEADCOUNT_ATS_NVH_NUMBER_CARD',
     MASKCOUNT_BH_PR_NUMBER_CARD = 'MASKCOUNT_BH_PR_NUMBER_CARD',
     ENTRANCE_EXIT_PER_HOUR = 'ENTRANCE_EXIT_PER_HOUR',
     MASK_COUNT = 'MASK_COUNT',
     TOP_TEN_CAR_BRAND = 'TOP_TEN_CAR_BRAND',
     VEHICLE_PURCHASING_POWER = 'VEHICLE_PURCHASING_POWER',
     MODE_TRANSPORTATION_BY_HOUR = 'MODE_TRANSPORTATION_BY_HOUR',
     DYNAMIC_GOAL_CUSTOM = 'DYNAMIC_GOAL_CUSTOM',
     HIGHLIGHT_NUMBER_CARD = 'HIGHLIGHT_NUMBER_CARD',
     CUSTOM_100_STACK_BAR = 'CUSTOM_100_STACK_BAR',
     CUSTOM_LINE_CHART = 'CUSTOM_LINE_CHART',
     CUSTOM_BAR_CHART = 'CUSTOM_BAR_CHART',
     AVERAGE_TRAFFIC_DAY_TYPE = 'AVERAGE_TRAFFIC_DAY_TYPE',
     CUSTOM_PIE_CHART = 'CUSTOM_PIE_CHART',
     CUSTOM_NUMBER_CARD = 'CUSTOM_NUMBER_CARD',
     MONTH_PERFORMANCE = 'MONTH_PERFORMANCE',
     DYNAMIC_DIVIDER_SELECTOR = 'DYNAMIC_DIVIDER_SELECTOR',
     CUSTOM_SANKEY_CHART = 'CUSTOM_SANKEY_CHART',
     ZONE_ENTRANCE_EXIT_ANALYSIS = 'ZONE_ENTRANCE_EXIT_ANALYSIS',
     CUSTOM_HEATMAP_CHART = 'CUSTOM_HEATMAP_CHART',
     DYNAMIC_AGE_PROFILE = 'DYNAMIC_AGE_PROFILE',
     DYNAMIC_GENDER_PROFILE = 'DYNAMIC_GENDER_PROFILE',
     DYNAMIC_ECHART = 'DYNAMIC_ECHART',
     STAFF_OCCUPANCY = 'STAFF_OCCUPANCY',
     STAFF_TRAFFIC_DURATION = 'STAFF_TRAFFIC_DURATION',
     MALE_FEMALE_TRAFFIC = 'MALE_FEMALE_TRAFFIC',
     NUMBER_SECURITY_GUARD = 'NUMBER_SECURITY_GUARD',
     MALE_FEMALE_LINE_CHART = 'MALE_FEMALE_LINE_CHART',
     MALE_USED_COUNT = 'MALE_USED_COUNT',
     FEMALE_USED_COUNT = 'FEMALE_USED_COUNT',
     FLOOR_SUMMARY_TOILET_USED = 'FLOOR_SUMMARY_TOILET_USED',
     STORE_AGE_PROFILE = 'STORE_AGE_PROFILE',
     DEMOGRAPHIC_PROFILE_BAR = 'DEMOGRAPHIC_PROFILE_BAR',
     ETHNICITY_PROFILE_PURCHASE_RATE = 'ETHNICITY_PROFILE_PURCHASE_RATE',
     AVERAGE_WEEKDAY_WEEKEND_COUNT = 'AVERAGE_WEEKDAY_WEEKEND_COUNT',
     SIMPLE_LINE = 'SIMPLE_LINE',
     SIMPLE_PIE = 'SIMPLE_PIE',
     SIMPLE_NUMBER_CARD = 'SIMPLE_NUMBER_CARD',
     SIMPLE_BAR = 'SIMPLE_BAR'
}

export class GraphResolver {
     private static readonly GraphResolverMapper: Readonly<{ [graph in GraphList]: GraphResolverBase }> = {
          [GraphList.DYNAMIC_LINE_SELECTABLE]: new DynamicLineChartSelectableResolver(DynamicLineChartWrapperComponent, GraphList.DYNAMIC_LINE_SELECTABLE, GraphDependency.DYNAMIC),
          [GraphList.DYNAMIC_BAR_SELECTABLE]: new DynamicBarChartSelectableResolver(DynamicBarChartWrapperComponent, GraphList.DYNAMIC_BAR_SELECTABLE, GraphDependency.DYNAMIC),
          [GraphList.HEADCOUNT]: new HeadcountNumberCardResolver(DynamicNumberCardComponent, GraphList.HEADCOUNT, GraphDependency.ENTRANCE_EXIT),
          [GraphList.DURATION_VISIT_TREND]: new DurationOfVisitTrendResolver(DynamicLineChartWrapperComponent, GraphList.DURATION_VISIT_TREND, GraphDependency.ENTRANCE_EXIT),
          [GraphList.DURATION_VISIT_NUMBERCARD]: new DurationOfVisitNumberCardResolver(DynamicNumberCardComponent, GraphList.DURATION_VISIT_NUMBERCARD, GraphDependency.ENTRANCE_EXIT),
          [GraphList.POPULAR_TIME_GRAPH]: new PopularTimeGraphResolver(DynamicLineChartWrapperComponent, GraphList.POPULAR_TIME_GRAPH, GraphDependency.ENTRANCE_EXIT_HOUR),
          [GraphList.POPULAR_TIME_BUSIEST_NUMBER_CARD]: new PopularTimeBusiestNumberCardResolver(DynamicNumberCardComponent, GraphList.POPULAR_TIME_BUSIEST_NUMBER_CARD, GraphDependency.ENTRANCE_EXIT_HOUR),
          [GraphList.GENDER_PROFILE]: new GenderProfileResolver(DynamicPieChartWrapperComponent, GraphList.GENDER_PROFILE, GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS),
          [GraphList.ETHNICITY_PROFILE]: new EthnicityProfileResolver(DynamicBarChartWrapperComponent, GraphList.ETHNICITY_PROFILE, GraphDependency.VISITOR_PROFILE_TWO_CROSS),
          [GraphList.AGE_PROFILE]: new AgeProfileResolver(DynamicBarChartWrapperComponent, GraphList.AGE_PROFILE, GraphDependency.VISITOR_PROFILE_TWO_CROSS),
          [GraphList.STORE_AGE_PROFILE]: new AgeStoreProfileResolver(DynamicBarChartWrapperComponent, GraphList.STORE_AGE_PROFILE, GraphDependency.STORE_AGE_PROFILE),
          [GraphList.AGE_PROFILE_BY_PIN]: new AgeProfileByPinResolver(DynamicBarChartWrapperComponent, GraphList.AGE_PROFILE, GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN),
          [GraphList.ZONE_AGE_PROFILE]: new ZoneAgeProfileByPinResolver(DynamicBarChartWrapperComponent, GraphList.AGE_PROFILE, GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO),
          [GraphList.ESTIMATED_TOURISTS]: new EstimateTouristResolver(DynamicPieChartWrapperComponent, GraphList.ESTIMATED_TOURISTS, GraphDependency.CUSTOMER_SEGREGATION),
          [GraphList.CUSTOMER_SEGMENTATION]: new CustomSegmentationResolver(DynamicBarChartWrapperComponent, GraphList.CUSTOMER_SEGMENTATION, GraphDependency.CUSTOMER_SEGREGATION),
          [GraphList.NON_ASIAN_ETHNICITY_PROFILE]: new NonAsianEthnicityProfileResolver(DynamicBarChartWrapperComponent, GraphList.NON_ASIAN_ETHNICITY_PROFILE, GraphDependency.VISITOR_PROFILE_TWO_CROSS),
          [GraphList.ASIAN_ETHNICITY_PROFILE]: new AsianEthnicityProfileResolver(DynamicBarChartWrapperComponent, GraphList.ASIAN_ETHNICITY_PROFILE, GraphDependency.VISITOR_PROFILE_TWO_CROSS),
          [GraphList.REPETATED_VISITORS]: new RepeatedVisitorsResolver(DynamicPieChartWrapperComponent, GraphList.REPETATED_VISITORS, GraphDependency.REPETATED_VISITORS),
          [GraphList.FREQUENCY_VISIT]: new FrequencyVisitResolver(DynamicPieChartWrapperComponent, GraphList.FREQUENCY_VISIT, GraphDependency.FREQUENCY_OF_VISIT),
          [GraphList.MODE_TRANSPORTATION]: new ModeOfTransportResolver(DynamicLineChartWrapperComponent, GraphList.MODE_TRANSPORTATION, GraphDependency.MODE_OF_TRANSPORTATION),
          [GraphList.VEHICLE_PROVINCE]: new VehicleByProvinceResolver(DynamicLineChartWrapperComponent, GraphList.VEHICLE_PROVINCE, GraphDependency.VEHICLE_BY_PROVINCE),
          [GraphList.ENTRANCE_EXIT_ANALYSIS]: new EntranceExitAnalysisResolver(DynamicBarChartWrapperComponent, GraphList.ENTRANCE_EXIT_ANALYSIS, [GraphDependency.FLOOR_ENTRANCE_EXIT_GATE_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_PIN_HOUR,
          GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, GraphDependency.ZONE_AREA_ENTRANCE_EXIT_ALL_PIN, GraphDependency.ENTRANCE_EXIT_PIN_HOUR, GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN_AVG]),
          [GraphList.ENTRANCE_EXIT_BIHOURS]: new EntranceExitPerTwoHourResolver(DynamicBarChartWrapperComponent, GraphList.ENTRANCE_EXIT_BIHOURS, [GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR, GraphDependency.ENTRANCE_EXIT_HOUR_NUMBER_CARD]),
          [GraphList.HEADCOUNT_DIVIDER]: new HeadcountDividerResolver(DynamicItemDividerComponent, GraphList.HEADCOUNT_DIVIDER, null),
          [GraphList.PURCHASE_BEHAVIOR_DIVIDER]: new PurchaseBehaviorDividerResolver(DynamicItemDividerComponent, GraphList.PURCHASE_BEHAVIOR_DIVIDER, null),
          [GraphList.VISITING_BEHAVIOR_DIVIDER]: new VisitingBehaviorDividerResolver(DynamicItemDividerComponent, GraphList.VISITING_BEHAVIOR_DIVIDER, null),
          [GraphList.NET_SHOPPING_HOUR_DIVIDER]: new NetShoppingHourDividerResolver(DynamicItemDividerComponent, GraphList.NET_SHOPPING_HOUR_DIVIDER, null),
          [GraphList.VISITOR_TRAFFIC_TREND]: new VisitorTrafficTrendResolver(DynamicLineChartWrapperComponent, GraphList.VISITOR_TRAFFIC_TREND,
               [GraphDependency.VISITOR_PROFILE_TWO_CROSS, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]), // TODO: when all should use data from ENTRANCE_EXIT
          [GraphList.VISITOR_TRAFFIC_VOLUME]: new VisitorTrafficHeadcountNumberCardResolver(DynamicNumberCardComponent, GraphList.VISITOR_TRAFFIC_VOLUME,
               [GraphDependency.VISITOR_PROFILE_TWO_CROSS, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]), // TODO: when all should use data from ENTRANCE_EXIT
          [GraphList.VISITOR_PURCHASE_RATE_TREND]: new VisitorPurchaseRateTrendResolver(DynamicLineChartWrapperComponent, GraphList.VISITOR_PURCHASE_RATE_TREND, [GraphDependency.PURCHASE_RATE, GraphDependency.PURCHASE_RATE_PROFILE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]),
          [GraphList.VISITOR_PURCHASE_RATE_NUMBER_CARD]: new VisitorPurchaseRateNumberCardResolver(DynamicNumberCardComponent, GraphList.VISITOR_PURCHASE_RATE_NUMBER_CARD,
               [GraphDependency.PURCHASE_RATE, GraphDependency.PURCHASE_RATE_PROFILE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]),
          [GraphList.VISITOR_DURATION_VISIT_TREND]: new VisitorDurationOfVisitTrendResolver(DynamicLineChartWrapperComponent, GraphList.VISITOR_DURATION_VISIT_TREND,
               [GraphDependency.ENTRANCE_EXIT, GraphDependency.AVERAGE_TIME_SPENT_PROFILE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]),
          [GraphList.VISITOR_DURATION_VISIT_NUMBER_CARD]: new VisitorAverageTimeSpentNumberCardResolver(DynamicNumberCardComponent, GraphList.VISITOR_DURATION_VISIT_NUMBER_CARD,
               [GraphDependency.ENTRANCE_EXIT, GraphDependency.AVERAGE_TIME_SPENT_PROFILE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]),
          [GraphList.VISITOR_NET_SHOPPING_HOUR_TREND]: new VisitorNetShoppingHourTrendResolver(DynamicLineChartWrapperComponent, GraphList.VISITOR_NET_SHOPPING_HOUR_TREND,
               [GraphDependency.ENTRANCE_EXIT, GraphDependency.NET_SHOPPING_TIME_PROFILE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]),
          [GraphList.VISITOR_NET_SHOPPING_HOUR_NUMBER_CARD]: new VisitorNetShoppingHourNumberCardResolver(DynamicNumberCardComponent, GraphList.VISITOR_NET_SHOPPING_HOUR_NUMBER_CARD, [GraphDependency.ENTRANCE_EXIT,
          GraphDependency.NET_SHOPPING_TIME_PROFILE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR]),
          [GraphList.TIME_VISIT_HEATMAP]: new TimeVisitHeatmapResolver(DynamicHeatmapWrapperComponent, GraphList.TIME_VISIT_HEATMAP, GraphDependency.ENTRANCE_EXIT_HOUR),
          [GraphList.PURCHASE_AVERAGE_TIME_SPENT_NUMBER_CARD]: new PurchaseRateAndAverageTimeSpentNumberCardResolver(DynamicDualNumberCardComponent, GraphList.PURCHASE_AVERAGE_TIME_SPENT_NUMBER_CARD, [GraphDependency.ENTRANCE_EXIT, GraphDependency.PURCHASE_RATE]),
          [GraphList.AVERAGE_CONGESTION]: new AverageCongestionNumberCardResolver(DynamicNumberCardComponent, GraphList.AVERAGE_CONGESTION, GraphDependency.TRAFFIC_CONGESTION),
          [GraphList.CURRENT_CUSTOMER]: new CurrentCustomerGaugeResolver(DynamicGaugeChartWrapperComponent, GraphList.CURRENT_CUSTOMER, [GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]),
          [GraphList.PARKING_LOT_OCCUPANCY]: new ParkingLotOccupancyResolver(DynamicGaugeChartWrapperComponent, GraphList.PARKING_LOT_OCCUPANCY, GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE),
          [GraphList.FLOOR_SUMMARY]: new FloorSummaryResolver(DynamicBarChartWrapperComponent, GraphList.FLOOR_SUMMARY, [GraphDependency.FLOOR_ENTRANCE_EXIT_GATE_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_PIN_HOUR, GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_ALL_PIN,
          GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR]),
          [GraphList.FLOOR_POPULAR_TIME]: new FloorPopularTimeGraphResolver(DynamicLineChartWrapperComponent, GraphList.POPULAR_TIME_GRAPH, [GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]),
          [GraphList.FLOOR_TRAFFIC_TREND]: new FloorTrafficTrendResolver(DynamicLineChartWrapperComponent, GraphList.FLOOR_TRAFFIC_TREND, [GraphDependency.ENTRANCE_EXIT, GraphDependency.ENTRANCE_EXIT_FLOOR, GraphDependency.ENTRANCE_EXIT_ZONE
               , GraphDependency.PREDICTION_ENTRANCE_EXIT, GraphDependency.PREDICTION_ENTRANCE_EXIT_FLOOR, GraphDependency.PREDICTION_ENTRANCE_EXIT_ZONE]),
          [GraphList.FLOOR_AVG_TIME_SPENT]: new FloorAvgTimeSpentResolver(DynamicLineChartWrapperComponent, GraphList.FLOOR_AVG_TIME_SPENT, [GraphDependency.ENTRANCE_EXIT, GraphDependency.ENTRANCE_EXIT_FLOOR, GraphDependency.ENTRANCE_EXIT_ZONE]),
          [GraphList.FLOOR_NET_SHOPPING_HOUR]: new FloorNetShoppingHourResolver(DynamicLineChartWrapperComponent, GraphList.FLOOR_NET_SHOPPING_HOUR, [GraphDependency.ENTRANCE_EXIT, GraphDependency.ENTRANCE_EXIT_FLOOR]),
          [GraphList.FLOOR_HEADCOUNT_CARD]: new FloorHeadcountNumberCardResolver(DynamicNumberCardComponent, GraphList.FLOOR_HEADCOUNT_CARD, [GraphDependency.ENTRANCE_EXIT, GraphDependency.ENTRANCE_EXIT_FLOOR, GraphDependency.ENTRANCE_EXIT_ZONE]),
          [GraphList.FLOOR_NET_SHOPPING_HOUR_PIE]: new FloorNetShoppingHourPieResolver(DynamicPieChartWrapperComponent, GraphList.FLOOR_NET_SHOPPING_HOUR_PIE, [GraphDependency.ENTRANCE_EXIT_FLOOR, GraphDependency.ENTRANCE_EXIT_ZONE]),
          [GraphList.FLOOR_FROM_TO]: new FloorFromToResolver(DynamicBarChartWrapperComponent, GraphList.FLOOR_FROM_TO, [GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, GraphDependency.ENTRANCE_EXIT_FLOW_FLOOR, GraphDependency.ENTRANCE_EXIT_FLOW_ZONE]),
          [GraphList.GATE_TRAFFIC]: new GateTrafficResolver(DynamicBarChartWrapperComponent, GraphList.GATE_TRAFFIC, [GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, GraphDependency.FLOOR_ENTRANCE_EXIT_GATE_HOUR,
          GraphDependency.ENTRANCE_EXIT_FLOOR_PIN_HOUR, GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR]),
          [GraphList.GATE_TIME_VISIT]: new GateTimeVisitResolver(DynamicBarChartWrapperComponent, GraphList.GATE_TIME_VISIT, [GraphDependency.FLOOR_ENTRANCE_EXIT_GATE_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_PIN_HOUR, GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR,
          GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_BY_HOUR_ALL_PIN]),
          [GraphList.OVERALL_TRAFFIC]: new OverallTrafficTrendResolver(DynamicLineChartWrapperComponent, GraphList.FLOOR_NET_SHOPPING_HOUR, GraphDependency.DYNAMIC), // [GraphDependency.ENTRANCE_EXIT, GraphDependency.ENTRANCE_EXIT_FLOOR, GraphDependency.ENTRANCE_EXIT_ZONE]
          [GraphList.OVERALL_HEADCOUNT]: new OverallHeadcountResolver(DynamicBarChartWrapperComponent, GraphList.OVERALL_HEADCOUNT, GraphDependency.DYNAMIC), // same as above
          [GraphList.OVERALL_AVG_TIME_SPENT]: new OverallAvgTimeSpentResolver(DynamicLineChartWrapperComponent, GraphList.OVERALL_AVG_TIME_SPENT, GraphDependency.DYNAMIC), // same as above
          [GraphList.OVERALL_AVG_TIME_SPENT_BAR]: new OverallAvgTimeSpentBarResolver(DynamicBarChartWrapperComponent, GraphList.OVERALL_AVG_TIME_SPENT_BAR, GraphDependency.DYNAMIC), // same as above
          [GraphList.OVERALL_NET_SHOPPING_HOUR]: new OverallNetShoppingTimeResolver(DynamicLineChartWrapperComponent, GraphList.OVERALL_NET_SHOPPING_HOUR, GraphDependency.DYNAMIC), // same as above
          [GraphList.OVERALL_NET_SHOPPING_HOUR_PIE]: new OverallNetShoppingTimePieResolver(DynamicPieChartWrapperComponent, GraphList.OVERALL_NET_SHOPPING_HOUR_PIE, GraphDependency.DYNAMIC), // same as above
          [GraphList.OVERALL_POPULAR_TIME]: new OverallPopularTimeResolver(DynamicLineChartWrapperComponent, GraphList.OVERALL_POPULAR_TIME, GraphDependency.DYNAMIC), // [GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]
          [GraphList.OVERALL_POPULAR_TIME_HEATMAP]: new OverallTimeVisitResolver(DynamicHeatmapWrapperComponent, GraphList.OVERALL_POPULAR_TIME_HEATMAP, GraphDependency.DYNAMIC), // same as above
          [GraphList.VEHICLE_PROVINCE_BAR]: new VehicleByProvinceBarResolver(DynamicBarChartWrapperComponent, GraphList.VEHICLE_PROVINCE_BAR, GraphDependency.VEHICLE_BY_PROVINCE),
          [GraphList.STORE_VISITOR_PROFILE_TRAFFIC_TREND]: new StoreVisitorTrafficTrendResolver(DynamicLineChartWrapperComponent, GraphList.STORE_VISITOR_PROFILE_TRAFFIC_TREND, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.STORE_FOOT_TRAFFIC]: new StoreFootTrafficResolver(DynamicBarChartWrapperComponent, GraphList.STORE_FOOT_TRAFFIC, GraphDependency.ENTRANCE_EXIT_STORE_HOUR),
          [GraphList.STORE_VISITOR_PROFILE_HEADCOUNT]: new StoreTrafficHeadcountNumberCardResolver(DynamicNumberCardComponent, GraphList.STORE_VISITOR_PROFILE_HEADCOUNT, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.STORE_VISITOR_DURATION_VISIT]: new StoreVisitorDurationOfVisitTrendResolver(DynamicLineChartWrapperComponent, GraphList.STORE_VISITOR_DURATION_VISIT, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.STORE_VISITOR_NET_SHOPPING_HOUR]: new StoreVisitorNetShoppingHourTrendResolver(DynamicLineChartWrapperComponent, GraphList.STORE_VISITOR_NET_SHOPPING_HOUR, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.STORE_VISITOR_PROFILE_DIVIDER]: new StoreVisitorProfileDividerResolver(DynamicItemDividerComponent, GraphList.STORE_VISITOR_PROFILE_DIVIDER, null),
          [GraphList.STORE_TENANT_DIVIDER]: new StoreTenantDividerResolver(DynamicItemDividerComponent, GraphList.STORE_TENANT_DIVIDER, null),
          [GraphList.STORE_VISITOR_AGE_PROFILE]: new StoreAgeProfileResolver(DynamicPieChartWrapperComponent, GraphList.STORE_VISITOR_AGE_PROFILE, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.STORE_VISITOR_GENDER_PROFILE]: new StoreGenderProfileResolver(DynamicPieChartWrapperComponent, GraphList.STORE_VISITOR_GENDER_PROFILE, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.STORE_VISITOR_ETHNICITY_PROFILE]: new StoreEthnicityProfileResolver(DynamicPieChartWrapperComponent, GraphList.STORE_VISITOR_ETHNICITY_PROFILE, GraphDependency.STORE_VISITOR_PROFILE),
          [GraphList.MALL_FOOT_TRAFFIC]: new MallFootTrafficResolver(DynamicLineChartWrapperComponent, GraphList.MALL_FOOT_TRAFFIC, GraphDependency.ENTRANCE_EXIT_HOUR),
          [GraphList.STORE_SHOPPING_NET_HOUR_CARD]: new StoreNetShoppingHourNumberCardResolver(DynamicNumberCardComponent, GraphList.STORE_SHOPPING_NET_HOUR_CARD, GraphDependency.ENTRANCE_EXIT_STORE),
          [GraphList.STORE_PURCHASE_RATE_AVG_TIME_CARD]: new StorePurchaseRateAndAverageTimeSpentNumberCardResolver(DynamicDualNumberCardComponent, GraphList.STORE_PURCHASE_RATE_AVG_TIME_CARD, [GraphDependency.ENTRANCE_EXIT_STORE, GraphDependency.STORE_PURCHASE_RATE_PROFILE]),
          [GraphList.FLOOR_ZONE_FOOT_TRAFFIC]: new FloorZoneFootTrafficResolver(DynamicLineChartWrapperComponent, GraphList.FLOOR_ZONE_FOOT_TRAFFIC, [GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, GraphDependency.ENTRANCE_EXIT_ZONE_HOUR]),
          [GraphList.HEADCOUNT_ATS_NVH_NUMBER_CARD]: new HeadcountATSNVHNumberCardResolver(DynamicDualNumberCardComponent, GraphList.HEADCOUNT_ATS_NVH_NUMBER_CARD, [GraphDependency.ENTRANCE_EXIT]),
          [GraphList.MASKCOUNT_BH_PR_NUMBER_CARD]: new MaskCountBHPRNumberCardResolver(DynamicDualNumberCardComponent, GraphList.MASKCOUNT_BH_PR_NUMBER_CARD, [GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.MASK_COUNT, GraphDependency.PURCHASE_RATE,
          GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS]),
          [GraphList.ENTRANCE_EXIT_PER_HOUR]: new EntranceExitPerHourResolver(DynamicBarChartWrapperComponent, GraphList.ENTRANCE_EXIT_PER_HOUR, GraphDependency.ENTRANCE_EXIT_HOUR),
          [GraphList.MASK_COUNT]: new MaskCountNumberCardResolver(DynamicNumberCardComponent, GraphList.MASK_COUNT, GraphDependency.MASK_COUNT),
          [GraphList.TOP_TEN_CAR_BRAND]: new TopTenCarBrandResolver(DynamicBarChartWrapperComponent, GraphList.TOP_TEN_CAR_BRAND, GraphDependency.CAR_BRAND),
          [GraphList.VEHICLE_PURCHASING_POWER]: new VehiclePurchasingPowerResolver(DynamicPieChartWrapperComponent, GraphList.VEHICLE_PURCHASING_POWER, GraphDependency.VEHICLE_PURCHASING_POWER),
          [GraphList.MODE_TRANSPORTATION_BY_HOUR]: new ModeOfTransportByHourResolver(DynamicLineChartWrapperComponent, GraphList.MODE_TRANSPORTATION_BY_HOUR, GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_BY_HOUR),
          [GraphList.DYNAMIC_GOAL_CUSTOM]: new DynamicCustomGoalCardResolver(DynamicProgressNumberCardComponent, GraphList.DYNAMIC_GOAL_CUSTOM, GraphDependency.DYNAMIC),
          [GraphList.HIGHLIGHT_NUMBER_CARD]: new HighlightNumberCardResolver(DynamicDualNumberCardComponent, GraphList.HIGHLIGHT_NUMBER_CARD, [GraphDependency.ENTRANCE_EXIT, GraphDependency.ENTRANCE_EXIT_HOUR, GraphDependency.MASK_COUNT,
          GraphDependency.PURCHASE_RATE, GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, GraphDependency.BUILDING_AVERAGE_DAY_TYPE, GraphDependency.MODE_OF_TRANSPORTATION]),
          [GraphList.CUSTOM_100_STACK_BAR]: new Custom100StackBarChartResolver(DynamicStackBarChartComponent, GraphList.CUSTOM_100_STACK_BAR, GraphDependency.DYNAMIC),
          [GraphList.CUSTOM_LINE_CHART]: new CustomLineChartResolver(DynamicCustomLineChartComponent, GraphList.CUSTOM_LINE_CHART, GraphDependency.DYNAMIC),
          [GraphList.AVERAGE_TRAFFIC_DAY_TYPE]: new AverageTrafficDayTypeNumberCardResolver(DynamicDualNumberCardComponent, GraphList.AVERAGE_TRAFFIC_DAY_TYPE, GraphDependency.BUILDING_AVERAGE_DAY_TYPE),
          [GraphList.CUSTOM_PIE_CHART]: new CustomPieChartResolver(DynamicCustomPieChartComponent, GraphList.CUSTOM_PIE_CHART, GraphDependency.DYNAMIC),
          [GraphList.CUSTOM_BAR_CHART]: new CustomBarChartResolver(DynamicCustomBarChartComponent, GraphList.CUSTOM_BAR_CHART, GraphDependency.DYNAMIC),
          [GraphList.CUSTOM_NUMBER_CARD]: new CustomNumberCardResolver(DynamicDualNumberCardComponent, GraphList.CUSTOM_NUMBER_CARD, GraphDependency.DYNAMIC),
          [GraphList.MONTH_PERFORMANCE]: new CalendarPerformanceResolver(CalendarViewComponent, GraphList.MONTH_PERFORMANCE, null),
          [GraphList.DYNAMIC_DIVIDER_SELECTOR]: new DynamicDividerSelectorResolver(DynamicDividerSelectorComponent, GraphList.DYNAMIC_DIVIDER_SELECTOR, GraphDependency.DYNAMIC),
          [GraphList.CUSTOM_SANKEY_CHART]: new CustomSankeyChartResolver(DynamicSankeyWrapperComponent, GraphList.CUSTOM_SANKEY_CHART, GraphDependency.DYNAMIC),
          [GraphList.ZONE_ENTRANCE_EXIT_ANALYSIS]: new ZoneEntranceExitAnalysisResolver(DynamicBarChartWrapperComponent, GraphList.ZONE_ENTRANCE_EXIT_ANALYSIS, GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_ALL_PIN),
          [GraphList.CUSTOM_HEATMAP_CHART]: new CustomHeatmapChartResolver(DynamicCustomHeatmapWrapperComponent, GraphList.CUSTOM_HEATMAP_CHART, GraphDependency.DYNAMIC),
          [GraphList.DYNAMIC_AGE_PROFILE]: new DynamicAgeProfileResolver(DynamicBarChartWrapperComponent, GraphList.DYNAMIC_AGE_PROFILE,
               [GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO, GraphDependency.VISITOR_PROFILE_TWO_CROSS, GraphDependency.ZONE_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN]),
          [GraphList.DYNAMIC_GENDER_PROFILE]: new DynamicGenderProfileResolver(DynamicPieChartWrapperComponent, GraphList.DYNAMIC_GENDER_PROFILE,
               [GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO, GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, GraphDependency.ZONE_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN]),
          [GraphList.DYNAMIC_ECHART]: new GenericEchartResolver(DynamicEchartComponent, GraphList.DYNAMIC_ECHART, GraphDependency.DYNAMIC),
          [GraphList.STAFF_OCCUPANCY]: new StaffOccupancyResolver(DynamicGaugeChartWrapperComponent, GraphList.STAFF_OCCUPANCY, GraphDependency.DYNAMIC),
          [GraphList.STAFF_TRAFFIC_DURATION]: new StaffTrafficDurationResolver(DynamicBarChartWrapperComponent, GraphList.STAFF_TRAFFIC_DURATION, GraphDependency.DYNAMIC),
          [GraphList.MALE_FEMALE_TRAFFIC]: new MaleFemaleTrafficeResolver(DynamicBarChartWrapperComponent, GraphList.MALE_FEMALE_TRAFFIC, GraphDependency.DYNAMIC),
          [GraphList.NUMBER_SECURITY_GUARD]: new NumberOfSecurityGuard(DynamicGaugeChartWrapperComponent, GraphList.NUMBER_SECURITY_GUARD, GraphDependency.DYNAMIC),
          [GraphList.MALE_FEMALE_LINE_CHART]: new MaleFemaleLineChartResolver(DynamicLineChartWrapperComponent, GraphList.MALE_FEMALE_LINE_CHART, GraphDependency.MALE_FEMALE_LINE_CHART),
          [GraphList.MALE_USED_COUNT]: new MaleUsedCountResolver(DynamicGaugeChartWrapperComponent, GraphList.MALE_USED_COUNT, GraphDependency.DYNAMIC),
          [GraphList.FEMALE_USED_COUNT]: new FemaleUsedCountResolver(DynamicGaugeChartWrapperComponent, GraphList.FEMALE_USED_COUNT, GraphDependency.DYNAMIC),
          [GraphList.FLOOR_SUMMARY_TOILET_USED]: new FloorSummaryToiletUsedResolver(DynamicBarChartWrapperComponent, GraphList.FLOOR_SUMMARY_TOILET_USED, GraphDependency.DYNAMIC),
          [GraphList.DEMOGRAPHIC_PROFILE_BAR]: new DemographicProfileBarResolver(DynamicBarChartWrapperComponent, GraphList.DEMOGRAPHIC_PROFILE_BAR, GraphDependency.DYNAMIC),
          [GraphList.ETHNICITY_PROFILE_PURCHASE_RATE]: new EthnicityProfilePurchaseRateResolver(DynamicBarChartWrapperComponent, GraphList.ETHNICITY_PROFILE_PURCHASE_RATE, GraphDependency.BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR),
          [GraphList.AVERAGE_WEEKDAY_WEEKEND_COUNT]: new AverageWeekDayWeekEndCountResolver(DynamicBarChartWrapperComponent, GraphList.AVERAGE_WEEKDAY_WEEKEND_COUNT, GraphDependency.ENTRANCE_EXIT),
          [GraphList.SIMPLE_LINE]: new SimpleLineResolver(DynamicLineChartWrapperComponent, GraphList.SIMPLE_LINE, GraphDependency.DYNAMIC),
          [GraphList.SIMPLE_PIE]: new SimplePieResolver(DynamicPieChartWrapperComponent, GraphList.SIMPLE_PIE, GraphDependency.DYNAMIC),
          [GraphList.SIMPLE_BAR]: new SimpleBarResolver(DynamicBarChartWrapperComponent, GraphList.SIMPLE_BAR, GraphDependency.DYNAMIC),
          [GraphList.SIMPLE_NUMBER_CARD]: new SimpleNumberCardResolver(SimpleNumberCardComponent, GraphList.SIMPLE_NUMBER_CARD, GraphDependency.DYNAMIC)
     };

     /**
      * fromValue convert graph name to its resolver with corresponding state
      *
      * @param name name of its graph if state is specified it will set the resolver state, if not, it will default to `ENABLE`
      * @example `MALL_TRAFFIC:LOCK` will be resolved to `GraphResolver.MallTrafficGraphResolver` with state `LOCK`
      */
     public static fromValue(name: string): [GraphResolverBase, string | DynamicGraphAdditionalInput | undefined] {
          const [graphName, state, additionalInput] = GraphResolver.parseGraphName(name);
          // Default state is enable if it gets to resolve it should be enable or lock
          const graphState = state === 'LOCK' ? 'LOCK' : state === 'DISABLE' ? 'DISABLE' : state === 'LOCK_COND' ? 'LOCK_COND' : 'ENABLE';
          const resolvedGraph = GraphResolver.GraphResolverMapper[graphName] as GraphResolverBase;
          if (resolvedGraph) {
               resolvedGraph.state = graphState;
               const parsedAdditionInput: string | DynamicGraphAdditionalInput | undefined = GraphResolver.parseAdditionalInput(additionalInput);
               return [resolvedGraph, parsedAdditionInput];
          }
          throw new RangeError(`unable recognize graph name ${graphName} (${name})`);
     }

     public static parseGraphName(name: string): [string, string, string] {
          const splittedInput = name.split(':');
          const [graphName, state] = splittedInput;
          const additionalInput = splittedInput.slice(2).join(':');
          return [graphName, state, additionalInput];
     }

     public static parseAdditionalInput(input: string) {
          let parsedAdditionInput: string | DynamicGraphAdditionalInput | undefined;
          try {
               parsedAdditionInput = JSON.parse(input) as DynamicGraphAdditionalInput;
          } catch (error) {
               parsedAdditionInput = input;
          }
          return parsedAdditionInput;
     }
}
