export const APP_VERSION = '5.4.75';

export const GOOGLE_MAP_JAVASCRIPT_API_TOKEN = 'AIzaSyDu2xGFYdGtNznWGLoIIAtJK1vFfJF7C98';

export const firebaseConfig = {
  apiKey: 'AIzaSyCRx07XeTSmjU93Rc9MWbP8HfrCu63aZGM',
  authDomain: 'retail-dashboard-1548930729150.firebaseapp.com',
  databaseURL: 'https://retail-dashboard-1548930729150.firebaseio.com',
  projectId: 'retail-dashboard-1548930729150',
  storageBucket: 'retail-dashboard-1548930729150.appspot.com',
  messagingSenderId: '143042028505',
  appId: '1:143042028505:web:f59b027bc4f7986dadafc8',
  measurementId: 'G-51L8XSTMBQ',
};

export const GOOGLE_ANALYTICS_TRACK_ID = 'UA-137490654-2';

// export const BACKEND_URL = 'http://192.168.1.116:5000/retail-dashboard-1548930729150/us-central1/asia_functions';
// export const BACKEND_URL = 'http://localhost:5000/retail-dashboard-1548930729150/us-central1/asia_functions';
// export const BACKENDV2_URL = 'http://localhost:5001/retail-dashboard-1548930729150/asia-northeast1/retail_customer_api_v2';
// export const BACKENDV2_CACHE_PREDICTOR_URL = 'http://localhost:5001/retail-dashboard-1548930729150/asia-northeast1/retail_customer_api_v2';

// Production
export const BACKEND_URL = 'https://asia-northeast1-retail-dashboard-1548930729150.cloudfunctions.net/asia_functions';
export const BACKENDV2_URL = 'https://asia-northeast1-retail-dashboard-1548930729150.cloudfunctions.net/retail_customer_api_v2';
export const BACKEND_CACHE_PREDICTOR_URL = 'https://backend.deepvisionapp.com/asia_functions';
export const BACKENDV2_CACHE_PREDICTOR_URL = 'https://backend.deepvisionapp.com/retail_customer_api_v2';
