import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, } from 'rxjs';

@Component({
  selector: 'app-dynamic-number-card',
  templateUrl: './dynamic-number-card.component.html',
  styleUrls: ['./dynamic-number-card.component.scss'],
})
export class DynamicNumberCardComponent implements OnInit {
  @Input() isShow = true;
  @Input() isLock: boolean | BehaviorSubject<boolean>;
  @Input() title: string | BehaviorSubject<string>;
  @Input() numberData$: BehaviorSubject<string>;
  @Input() label$: BehaviorSubject<string>;
  @Input() labelStyle$: BehaviorSubject<{ fontSize: string; fontWeight: string; color: string }>;
  @Input() labelIcon$: BehaviorSubject<string>;
  @Input() iconStyle: string;
  @Input() sizeXS: string;
  @Input() sizeMD: string;
  @Input() offsetMD: string;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isEnableTBD = false;
  @Input() height = '100%'

  constructor(
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }

  ngOnInit() { }

  get parseTitle() {
    let parseText: string;
    if (this.title instanceof BehaviorSubject) {
      this.title.subscribe(text => {
        parseText = text;
      });
    } else {
      parseText = this.title;
    }
    return parseText;
  }

}
