export enum GraphDependency {
    ENTRANCE_EXIT = 'entrance-exit',
    ENTRANCE_EXIT_MONTH = 'entrance-exit-month',
    ENTRANCE_EXIT_HOUR = 'entrance-exit-by-hour',
    ENTRANCE_EXIT_FLOOR = 'entrance-exit-by-floor',
    ENTRANCE_EXIT_FLOOR_HOUR = 'entrance-exit-by-floor-by-hour',
    ENTRANCE_EXIT_ZONE_HOUR = 'entrance-exit-by-zone-by-hour',
    ENTRANCE_EXIT_ZONE = 'entrance-exit-zone',
    ENTRANCE_EXIT_STORE_HOUR = 'entrance-exit-by-store-by-hour',
    ENTRANCE_EXIT_FLOW_BUILDING = 'entrance-exit-flow-building',
    ENTRANCE_EXIT_FLOW_FLOOR = 'entrance-exit-flow-floor',
    ENTRANCE_EXIT_FLOW_ZONE = 'entrance-exit-flow-zone',
    DYNAMIC = 'dynamic',
    TOURIST = 'tourist',
    PARKING_CUSTOMER = 'parking-customer',
    VISITOR_PROFILE = 'visitor-profile',
    CUSTOMER_SEGREGATION = 'customer-segregation',
    REPETATED_VISITORS = 'repeated-visitors',
    FREQUENCY_OF_VISIT = 'frequency-of-visit,',
    MODE_OF_TRANSPORTATION = 'mode-of-transportation',
    VEHICLE_BY_PROVINCE = 'vehicle-by-province',
    VEHICLE_BY_PROVINCE_UNGROUP = 'provinces-ungroup',
    FLOOR_ENTRANCE_EXIT_GATE_HOUR = 'entrance-exit-by-gate-by-hour',
    PURCHASE_RATE = 'purchase-rate',
    PURCHASE_RATE_PROFILE = 'purchase-rate-by-profile',
    AVERAGE_TIME_SPENT_PROFILE = 'average-timespent-by-profile',
    NET_SHOPPING_TIME_PROFILE = 'net-shopping-time-by-profile',
    TRAFFIC_CONGESTION = 'traffic-congestion',
    CAR_ENTRANCE_EXIT_HOUR = 'car-vehicle-entrance-exit-by-hour',
    WEATHER_MONTH = 'weather-month',
    STORE_VISITOR_PROFILE = 'store-visitor-profile',
    ENTRANCE_EXIT_STORE = 'entrance-exit-by-store',
    STORE_PURCHASE_RATE_PROFILE = 'store-purchase-rate-by-profile',
    ENTRANCE_EXIT_PIN_HOUR = 'entrance-exit-by-pin-by-hour',
    ENTRANCE_EXIT_FLOOR_PIN_HOUR = 'entrance-exit-floor-by-pin-by-hour',
    ENTRANCE_EXIT_ZONE_PIN_HOUR = 'entrance-exit-zone-by-pin-by-hour',
    MASK_COUNT = 'mask-count',
    FLOOR_HEATMAP = 'building-floor-heatmap',
    STORE_PROXIMITY_TRAFFIC = 'store-proximity-traffic',
    PREDICTION_STORE_PROXIMITY_TRAFFIC = 'prediction-store-proximity-traffic',
    PREDICTION_ENTRANCE_EXIT = 'prediction-entrance-exit',
    PREDICTION_ENTRANCE_EXIT_FLOOR = 'prediction-entrance-exit-by-floor',
    PREDICTION_ENTRANCE_EXIT_ZONE = 'prediction-entrance-exit-zone',
    CAR_BRAND = 'car-brand',
    VEHICLE_PURCHASING_POWER = 'vehicle-by-purchasing-power',
    MODE_OF_TRANSPORTATION_BY_HOUR = 'mode-of-transportation-by-hour',
    PREDICTION_VISITOR_PROFILE = 'prediction-visitor-profile',
    MONTH_ENTRANCE_EXIT = 'month-entrance-exit',
    MONTH_ENTRANCE_EXIT_ZONE = 'month-entrance-exit-zone',
    MONTH_VISITOR_PROFILE = 'month-visitor-profile',
    MULTIPLE_DAY_CUSTOM_SEGMENTATION = 'multiple-day-custom-segmentation',
    ZONE_VISITOR_PROFILE = 'zone-visitor-profile',
    MAP_MODEL3D_INFO = 'model3d',
    PLATE_TIMESPENT = 'plate-timespent',
    BUILDING_AVERAGE_DAY_TYPE = 'building-average-day-type',
    FREQ_VISIT_PURCHASING_POWER = 'freq-visit-purchasing-power',
    FREQ_VISIT_SEMI_ANNUAL = 'freq-visit-semi-annual',
    ZONE_VISITOR_PROFILE_SAMPLE = 'zone-visitor-profile-sample',
    VISITOR_PROFILE_SIMPLE_CROSS = 'visitor-profile-simple-cross',
    VISITOR_PROFILE_TWO_CROSS = 'visitor-profile-two-cross',
    MESSENGER_BRAND = 'messgener-brand',
    PURCHASAE_BAG_COLOR = 'purchase-bag-color',
    MESSENGER_BRAND_BY_HOUR = 'messenger-brand-by-hour',
    BUILDING_ENTRANCE_EXIT_SUM_BY_DAY = 'building-entrance-exit-sum-by-day',
    FREQ_VISIT_SEMI_ANNUAL_V2 = 'freq-visit-semi-annual-v2',
    FREQ_VISIT_SEMI_ANNUAL_SUM_BY_DAY = 'freq-visit-semi-annual-sum-by-day',
    VEHICLE_PURCHASING_POWER_SUM_BY_DAY = 'vehicle-by-purchasing-power-sum-by-day',
    VEHICLE_UNIQUE_VISITORS = 'vehicle-unique-visitors',
    TRAFFIC_SITE_COUNT = 'traffic-site-count',
    TRAFFIC_SITE_UNIQUE_VISITOR = 'traffic-site-unique-visitor',
    TRAFFIC_SITE_PURCHASING_POWER = 'traffic-site-purchasing-power',
    TRAFFIC_SITE_MODE_TRANSPORTATION = 'traffic-site-mode-transportation',
    TRAFFIC_SITE_MODE_TRANSPORTATION_PUBLIC_PRIVATE = 'traffic-site-mode-transportation-public-private',
    TRAFFIC_SITE_CAR_BRAND = 'traffic-site-car-brand',
    TRAFFIC_SITE_AVG_COUNT_BY_DAY_TYPE = 'traffic-site-avg-count-by-day-type',
    TRAFFIC_SITE_COUNT_BY_HOUR = 'traffic-site-count-by-hour',
    TRAFFIC_SITE_VEHICLE_PROFILE = 'traffic-site-vehicle-profile',
    TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR = 'traffic-site-vehicle-profile-by-hour',
    TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE = 'traffic-site-vehicle-profile-public-private',
    TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR = 'traffic-site-vehicle-profile-unique-visitor',
    TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE = 'traffic-site-vehicle-profile-unique-visitor-public-private',
    GOAL_ZONE_ENTRANCE_EXIT = 'goal-zone-entrance-exit',
    GOAL_BUILDING_ENTRANCE_EXIT = 'goal-building-entrance-exit',
    GOAL_BUILDING_ENTRANCE_EXIT_2 = 'goal-building-entrance-exit-2',
    VEHICLE_PARKING_CAR_BRAND = 'vehicle-parking-car-brand',
    VEHICLE_PARKING_PROVINCE = 'vehicle-parking-province',
    VEHICLE_PARKING_PROVINCE_UNGROUP = 'vehicle-parking-province-ungroup',
    VEHICLE_PARKING_MODE_TRANSPORTATION = 'vehicle-parking-mode-transportation',
    VEHICLE_PARKING_MODE_TRANSPORTATION_BY_HOUR = 'vehicle-parking-mode-transportation-by-hour',
    VEHICLE_PARKING_ENTRANCE_EXIT = 'vehicle-parking-entrance-exit',
    VEHICLE_PARKING_PURCHASING_POWER = 'vehicle-parking-purchasing-power',
    VEHICLE_PARKING_ENTRANCE_EXIT_P2 = 'vehicle-parking-entrance-exit-p2',
    VEHICLE_PARKING_MODE_TRANSPORTATION_P2 = 'vehicle-parking-mode-transportation-p2',
    VEHICLE_PARKING_ENTRANCE_EXIT_P3 = 'vehicle-parking-entrance-exit-p3',
    MOCK_STORE_COUNT = 'mock-store-count',
    MOCK_STORE_VISITOR = 'mock-store-visitor',
    MOCK_STORE_VISITOR_BY_HOUR = 'mock-store-visitor-by-hour',
    MOCK_STORE_COUNT_AVG_BY_DAY_TYPE = 'mock-store-count-avg-by-day-type',
    MOCK_STORE_VISITOR_AVG_BY_DAY_TYPE = 'mock-store-visitor-avg-by-day-type',
    MOCK_ADS_VISITOR_PROFILE = 'mock-ads-visitor-profile',
    MOCK_ADS_VISITOR_PROFILE_BY_HOUR = 'mock-ads-visitor-profile-by-hour',
    TRAFFIC_SITE_VISUALIZATION_VIDEOS = 'traffic-site-visualization-videos',
    LIDAR_AREA_ENGAGEMENT_COUNT = 'lidar-area-engagement-count',
    LIDAR_AREA_CONVERSION_RATE = 'lidar-area-conversion-rate',
    LIDAR_AREA_IMPRESSION = 'lidar-area-impression',
    LIDAR_AREA_REACH = 'lidar-area-reach',
    LIDAR_AREA_ENGAGEMENT_MINUTES = 'lidar-area-engagement-minutes',
    LIDAR_AREA_AVG_ENGAGEMENT_MINUTES = 'lidar-area-avg-engagement-minutes',
    LIDAR_AREA_IMPRESSION_BY_HOUR = 'lidar-area-impression-by-hour',
    LIDAR_AREA_REACH_BY_HOUR = 'lidar-area-reach-by-hour',
    LIDAR_AREA_ENGAGEMENT_COUNT_BY_HOUR = 'lidar-area-engagement-count-by-hour',
    LIDAR_AREA_VISITOR_PROFILE = 'lidar-area-visitor-profile',
    TRAFFIC_SITE_ALL_VISUALIZATION_VIDEOS = 'traffic-site-all-visualization-videos',
    BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN = 'building-zone-traffic-flow-one-direction-unmerged-by-pin',
    BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN = 'building-zone-traffic-flow-unmerged-by-pin',
    BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN = 'building-zone-traffic-flow-by-pin',
    BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN = 'building-zone-traffic-flow-no-lookback-by-pin',
    BUILDING_ZONE_SYNERGY_BY_PIN = 'building-zone-synergy-by-pin',
    BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN = 'building-visitor-profile-cross-level-by-one-by-pin',
    BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN = 'building-visitor-profile-cross-level-by-two-by-pin',
    BUILDING_TIMESPENT_BY_PIN = 'building-timespent-by-pin',
    BUILDING_MESSENGER_BRAND_BY_PIN = 'building-messenger-brand-by-pin',
    BUILDING_AREA_ENTRANCE_EXIT_BY_PIN = 'building-area-entrance-exit-by-pin',
    ZONE_AREA_ENTRANCE_EXIT_BY_PIN = 'zone-area-entrance-exit-by-pin',
    ALL_ZONE_AREA_ENTRANCE_EXIT = 'all-zone-area-entrance-exit',
    ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_ONE = 'all-zone-area-visitor-profile-cross-level-by-one',
    ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO = 'all-zone-area-visitor-profile-cross-level-by-two',
    ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR = 'zone-area-entrance-exit-by-pin-by-hour',
    ALL_ZONE_AREA_ENTRANCE_EXIT_BY_HOUR = 'all-zone-area-entrance-exit-by-hour',
    ALL_ZONE_TIMESPENT = 'all-zone-timespent',
    ALL_ZONE_TO_ZONE_SYNERGY = 'all-zone-to-zone-synergy',
    ZONE_AREA_ENTRANCE_EXIT_ALL_PIN = 'zone-area-entrance-exit-all-pin',
    ZONE_AREA_ENTRANCE_EXIT_BY_PIN_AVG_DAY_TYPE = 'zone-area-entrance-exit-by-pin-avg-by-day-type',
    ALL_ZONE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE = 'all-zone-area-entrance-exit-avg-by-day-type',
    BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN = 'building-area-entrance-exit-all-pin',
    BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN_AVG = 'building-area-entrance-exit-all-pin-by-avg',
    BUILDING_AREA_ENTRANCE_EXIT_BY_HOUR_ALL_PIN = 'building-area-entrance-exit-by-hour-all-pin',
    FLOOR_AREA_ENTRANCE_EXIT_ALL_PIN = 'floor-area-entrance-exit-all-pin',
    ALL_ZONE_AREA_ENTRANCE_EXIT_ALL_PIN = 'all-zone-area-entrance-exit-all-pin',
    BUILDING_TIMESPENT = 'building-timespent',
    BUILDING_ZONE_SYNERGY = 'building-to-zone-synergy',
    BUILDING_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE = 'building-area-entrance-exit-avg-by-day-type',
    BUILDING_AREA_VISITOR_PROFILE_CROSS_LEVEL_FOUR = 'building-area-visitor-profile-cross-level-by-four',
    BUILDING_ZONE_SYNERGY_ALL_PIN = 'building-to-zone-synergy-all-pin',
    BUILDING_ENTRANCE_EXIT_AVG_DAY_TYPE = 'building-entrance-exit-average-by-day-type',
    FLOOR_AREA_ENTRANCE_EXIT_BY_PIN = 'building-floor-entrance-exit-by-pin',
    STORE_AREA_ENTRANCE_EXIT = 'store-area-entrance-exit',
    STORE_AREA_ENTRANCE_EXIT_HOUR = 'store-area-entrance-exit-by-hour',
    STORE_AREA_ZONE_SYNERGY = 'store-area-to-zone-synergy',
    STORE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE = 'store-area-entrance-exit-average-by-day-type',
    ZONE_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN = 'zone-visitor-profile-cross-level-by-two-by-pin',
    BUILDING_AREA_VISTIOR_PROFILE_CROSS_LEVEL_TWO = 'building-visitor-profile-cross-level-by-two',
    BUILDING_AREA_ENTRANCE_EXIT_HOUR = 'building-area-entrance-exit-by-hour',
    VEHICLE_PARKING_ENTRANCE_EXIT_HOUR = 'vehicle-parking-entrance-exit-by-hour',
    VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA = 'vehicle-parking-mode-transportation-all-area',
    VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA_HOUR = 'vehicle-parking-mode-transportation-all-area-by-hour',
    BUILDING_TO_BUILDING_SYNERGY = 'building-to-building-synergy',
    FLOOR_STORE_AREA = 'floor-store-area',
    FLOOR_STORE_AREA_CATEGORY = 'floor-store-area-by-category',
    BUILDING_TRAFFIC_BREAKDOWN = 'building-traffic-breakdown',
    BUILDING_UNIQUE_TRAFFIC_BREAKDOWN = 'building-unqiue-traffic-breakdown',
    TRAFFIC_SITE_COUNT_ALL_AREA = 'traffic-site-count-all-area',
    PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE = 'predictive-traffic-site-vehicle-profile',
    PREDICTIVE_TRAFFIC_SITE_COUNT = 'predictive-traffic-site-count',
    PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR = 'predictive-traffic-site-vehicle-profile-unique-visitor',
    PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE = 'prediction-traffic-site-vehicle-profile',
    PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE = 'prediction-traffic-site-vehicle-public-private',
    PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR = 'prediction-traffic-site-vehicle-profile-unique-visitor',
    PREDICTION_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE = 'prediction-traffic-site-vehicle-profile-unique-visitor-public-private',
    TRAFFIC_SITE_PACKAGE_COUNT_BY_HOUR = 'traffic-site-package-count-by-hour',
    TRAFFIC_SITE_PACKAGE_COUNT = 'traffic-site-package-count',
    TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE = 'traffic-site-package-vehicle-profile',
    TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR = 'traffic-site-package-vehicle-profile-by-hour',
    TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_PUBLIC_PRIVATE = 'traffic-site-package-vehicle-profile-public-private',
    VEHICLE_PARKING_ENTRANCE_EXIT_MONTHLY = 'vehicle-parking-entrance-exit-monthly',
    VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP = 'vehicle-parking-province-ungroup-all-area',
    VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE = 'vehicle-parking-entrance-exit-avg-by-day-type',
    VEHICLE_PARKING_ALL_AREA_PURCHASING_POWER = 'vehicle-parking-purchasing-power-all-area',
    VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY = 'vehicle-parking-to-vehicle-parking-synergy',
    VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT = 'vehicle-parking-plate-timespent-all-area',
    VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT = 'vehicle-parking-to-vehicle-parking-unique-area-visit',
    VEHICLE_PARKING_PLATE_NUMBER_DEFINITION = 'vehicle-parking-plate-number-definition',
    VEHICLE_PARKING_VEHICLE_PROFILE = 'vehicle-parking-vehicle-profile',
    VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA = 'vehicle-parking-vehicle-profile-all-area',
    VEHICLE_PARKING_VEHICLE_PROFILE_AVG_DAY_TYPE = 'vehicle-parking-vehicle-profile-avg-by-day-type',
    VEHICLE_PARKING_VEHICLE_PROFILE_HOUR = 'vehicle-parking-vehicle-profile-by-hour',
    VEHICLE_PARKING_VEHICLE_PROFILE_PIN = 'vehicle-parking-vehicle-profile-by-pin',
    VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR = 'vehicle-parking-vehicle-profile-by-pin-by-hour',
    VEHICLE_PARKING_ENTRANCE_EXIT_ALL_PIN = 'vehicle-parking-entrance-exit-all-pin',
    VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY_ALL_AREA = 'vehicle-parking-to-vehicle-parking-synergy-all-area',
    BUILDING_FLOOR_TIMESPENT = 'building-floor-timespent',
    STORE_PROXIMITY_FLOOR_TRAFFIC = 'store-proximity-by-floor',
    STORE_AREA_ZONE_TRAFFIC_FLOW = 'store-area-to-zone-traffic-flow',
    BUILDING_AREA_ZONE_TRAFFIC_FLOW = 'building-area-to-zone-traffic-flow',
    VEHICLE_PARKING_FREQ_VISIT = 'vehicle-parking-frequency-of-visit',
    VEHICLE_PARKING_REPEATED_VISITORS = 'vehicle-parking-repeated-visitors',
    VEHICLE_PARKING_RECENCY_FREQUENCY = 'vehicle-parking-recency-frequency',
    ENTRANCE_EXIT_HOUR_NUMBER_CARD = 'entrance-exit-number-card',
    BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK = 'building-area-entrance-exit-by-hour-avg-day-of-week',
    BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_WEEK = 'building-area-entrance-exit-by-hour-sum-day-of-week',
    BUILDING_ENTRANCE_EXIT_AVG = 'building-entrance-exit-by-daily-average',
    PREDICTION_BUILDING_ENTRANCE_EXIT_AVG = 'prediciton-building-entrance-exit-by-daily-average',
    ENTRANCE_EXIT_FLOW_BUILDING_AVG = 'entrance-exit-flow-building-by-daily-average',
    TRAFFIC_SITE_ADS_FREQUENCY = 'traffic-site-ads-frequency',
    STAFF_TRAFFIC_COUNT = 'staff-traffic-count',
    STAFF_TRAFFIC_COUNT_2 = 'staff-traffic-count-2',
    FEMALE_TRAFFIC_COUNT = 'female-traffic-count',
    MALE_TRAFFIC_COUNT = 'male-traffic-count',
    STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN_2 = 'store-area-entrance-exit-hour-breakdown',
    STORE_SHOPPING_BAG_RATE = 'store-shopping-bag-rate',
    MALE_FEMALE_LINE_CHART = 'male-female-line-chart',
    MALE_COUNT_BY_PIN = 'male-count-by-pin',
    FEMALE_COUNT_BY_PIN = 'female-count-by-pin',
    STORE_AGE_PROFILE = 'store-age-profile',
    MALE_BUILDING_COUNT = 'male-buidling-count',
    FEMALE_BUILDING_COUNT = 'female-buidling-count',
    BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY = 'building-area-entrance-exit-hout-sum-day-by-day-group-day-by-day',
    VEHICLE_PARKING_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY = 'vehicle-parking-entrance-exit-hour-sum-day-by-day-group-day-by-day',
    VEHICLE_PARKING_FREQ_VISIT_PROFILE = 'vehicle-parking-frequency-of-visit-profile',
    VEHICLE_PARKING_REPEATED_VISITORS_PROFILE = 'vehicle-parking-repeated-visitors-profile',
    VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE = 'vehicle-parking-recency-frequency-profile',
    VEHICLE_PARKING_TIMESPENT_PROFILE = 'vehicle-parking-timespent-profile',
    FLOOR_AREA_ENTRANCE_EXIT = 'floor-area-entrance-exit',
    STORE_NO_PASSERBY_PURCHASE_VISITOR_PROFILE = 'store-no-passerby-purchase-visitor-profile',
    STORE_PASSERBY_ENTRANCE_EXIT = 'store-passerby-entrance-exit',
    STORE_PASSERBY_ENTRANCE_EXIT_HOUR = 'store-passerby-entrance-exit-by-hour',
    STORE_ZONE_VIRTUAL_GRID_AREA_ENTRANCE_EXIT = 'store-zone-virtual-grid-area-entrance-exit',
}
