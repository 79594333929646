import { SelectableDataName } from './../objects/selectableData';
import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { GenericPieChartData } from '../objects/chart';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { DynamicPieChartWrapperComponent } from '../shared-components/dynamic/dynamic-pie-chart-wrapper/dynamic-pie-chart-wrapper.component';
import { PIE_CHART_COLOR_LIST } from '../configs/color';

export class SimplePieResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        const colorMapping = {
            'children': 'Children',
            'teenagers': 'Teenagers',
            'young_adults': 'Young Adults',
            'adults': 'Adults',
            'seniors': 'Seniors',
            'asian': 'Asian',
            'white': 'Western',
            'middle_eastern': 'Middle-East',
            'indian': 'Indian',
            'black': 'Africa',
        }
        // load data here
        const datasourceKey = (getAdditionalInput(additionalInput, 'datasourceKey')) as string;
        const postProcessDataKey = (getAdditionalInput(additionalInput, 'postProcessDataKey')) as string;
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey')) as string;
        graphDataService.baseGraphData.addDependency(GraphDependency[datasourceKey])
        const chartData$ = new BehaviorSubject<GenericPieChartData[]>([]);
        subscription.add(combineLatest(graphDataService[postProcessDataKey])
            .subscribe((data: any[]) => {
                if (data.every(p => p === null)) {
                    return;
                }
                const chartData: GenericPieChartData[] = Object.entries(data[0]).map(([k, v], i) => {
                    return {
                        data: v,
                        label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[k] || k,
                        // color: colorMapping[k] || PIE_CHART_COLOR_LIST[i % Object.entries(data[0]).length]
                        color: PIE_CHART_COLOR_LIST[i % Object.entries(data[0]).length]
                    } as GenericPieChartData
                })
                chartData$.next(chartData);
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicPieChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.isLock = this.isLock;
        comInstance.data$ = chartData$;
        return comInstance;
    }

}
