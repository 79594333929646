import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BACKENDV2_URL } from '../configs/api';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { User } from '../objects/user';
import jwt_decode from 'jwt-decode';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user$: BehaviorSubject<firebase.default.User> = new BehaviorSubject(null);
  userProfile$: BehaviorSubject<User> = new BehaviorSubject(null);
  USER_ROLES: any;
  adminSession: boolean;
  private lastIdTokenTime: number;
  private idToken: string;

  constructor(
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.afAuth.onAuthStateChanged(async firebaseUser => {
      if (!firebaseUser) { return; }
      this.user$.next(firebaseUser);
      const [userProfile, userRoles, adminAuthorizationToken] = await Promise.all([this.fetchUserProfile(), this.fetchUserRoles(), this.adminAuthorization()]);
      const adminAuthorization = jwt_decode(adminAuthorizationToken) as { isAdmin: boolean };
      this.userProfile$.next(userProfile);
      this.USER_ROLES = userRoles;
      this.adminSession = adminAuthorization.isAdmin;
    });
  }

  get userProfile() {
    return this.userProfile$.value;
  }

  get firebaseUser(): Promise<firebase.default.User> {
    if (!this.user$.value) {
      return Promise.reject('AngularFireAuth is not yet ready');
    } else {
      return Promise.resolve(this.user$.value);
    }
  }

  public async getIdToken(forceRefresh?: boolean): Promise<string> {
    // case force refresh
    if (forceRefresh) {
      const idToken = await (await this.firebaseUser).getIdToken(forceRefresh);
      this.idToken = idToken;
      this.lastIdTokenTime = Date.now();
    }
    // case normally
    else if (!this.idToken || !this.lastIdTokenTime || (Date.now() - this.lastIdTokenTime > 5 * 60 * 1000)) {
      const idToken = await (await this.firebaseUser).getIdToken(true);
      this.idToken = idToken;
      this.lastIdTokenTime = Date.now();
    }
    return Promise.resolve(this.idToken);
  }

  get isUserLoggedIn(): boolean {
    return this.user$.value ? true : false;
  }

  getCookie(cookieName: string) {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookie = decodedCookie.split(';');
    for (let c of cookie) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  }

  async logout(forceLogOut?: boolean) {
    return this.sessionLogout()
      .then(() => this.afAuth.setPersistence('none'))
      .then(() => this.afAuth.signOut())
      // .then(() => this.user$.next(null))
      .then(() => {
        if (forceLogOut) {
          // TODO: find a effective solution for delete cache data when logout 
          // return this.router.navigateByUrl('/');
          return window.location.assign('/');
        }
        return;
        // return window.location.reload();
      })
      .catch(err => console.error(err));
  }

  async loginByEmail(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then(async userCredential => {
      if (userCredential.user) {
        this.idToken = await userCredential.user.getIdToken(true);
        if (userCredential.user.getIdTokenResult(true)) {
          this.lastIdTokenTime = Date.now();
          // Session login endpoint is queried and the session cookie is set.
          // CSRF protection should be taken into account.
          const csrfToken = this.getCookie('csrfToken');
          return this.postIdTokenToSessionLogin(this.idToken, csrfToken);
        }
        throw new Error('Authenication Failed');
      }
    })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        throw new Error(`${errorCode}: ${errorMessage}`);
      });
  }

  async postIdTokenToSessionLogin(idToken: string, csrfToken: string) {
    const requestURL = `${BACKENDV2_URL}/api/v2/auth/session-login`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const userDataBody = {
      idToken,
      csrfToken
    };
    return lastValueFrom(this.http.post(requestURL, userDataBody, httpOptions));
  }

  async sessionLogout() {
    const requestURL = `${BACKENDV2_URL}/api/v2/auth/session-logout`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    return lastValueFrom(this.http.post(requestURL, {}, httpOptions));
  }

  async signupByEmailAndPassword(email: string, password: string, first_name: string, last_name: string, position: string, position_id: number) {
    const idToken = await this.getIdToken();
    const requestURL = `${BACKENDV2_URL}/api/v2/auth/signup`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const userDataBody = {
      idToken,
      email,
      password,
      first_name,
      last_name,
      position,
      position_id,
    };
    return lastValueFrom(this.http.post(requestURL, userDataBody, httpOptions));
  }

  async fetchUserProfile() {
    const apiURL = `/retail_customer_api_v2/api/v2/user/user-profile`;
    return this.dataService.fetchData(apiURL);
  }

  async fetchUserRoles() {
    const requestURL = `/retail_customer_api_v2/api/v2/user/user-roles`;
    return this.dataService.fetchData(requestURL);
  }

  forgotPassword(email: string): Promise<any> {
    const apiURL = `${BACKENDV2_URL}/api/v2/auth/forgot-password`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const httpBody = {
      email
    };
    return lastValueFrom(this.http.post(apiURL, httpBody, httpOptions));
  }

  verifyActionCode(oobCode: string): Promise<any> {
    return this.afAuth.checkActionCode(oobCode);
  }

  changePassword(newPassword: string, oobCode: string): Promise<any> {
    return this.afAuth.confirmPasswordReset(oobCode, newPassword);
  }

  async sessionAttempt(email: string) {
    const requestURL = `${BACKENDV2_URL}/api/v2/auth/session-attempt`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const userDataBody = {
      email
    };
    return lastValueFrom(this.http.post(requestURL, userDataBody, httpOptions));
  }

  async adminAuthorization() {
    const requestURL = `/retail_customer_api_v2/api/v2/auth/admin-session`;
    return this.dataService.fetchData(requestURL);
  }

  async requestURLwithIdToken(requestURL: string, isHaveQueryParams: boolean) {
    const idToken = await this.getIdToken();
    return isHaveQueryParams ? `${requestURL}&idToken=${idToken}` : `${requestURL}?idToken=${idToken}`;
  }

}
