import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { RawDataGuard } from './guards/raw-data.guard';
import { ManageUserGuard } from './guards/manage-user.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer',
    loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/event/event.module').then(m => m.EventPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [AuthGuard, ManageUserGuard],
  },
  {
    path: 'export-data',
    loadChildren: () => import('./pages/export-data/export-data.module').then(m => m.ExportDataPageModule),
    canActivate: [AuthGuard, RawDataGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'traffic',
    loadChildren: () => import('./pages/traffic/traffic.module').then(m => m.TrafficPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'store-traffic',
    loadChildren: () => import('./pages/store-traffic/store-traffic.module').then(m => m.StoreTrafficPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then(m => m.ForgetPasswordPageModule),
  },
  {
    path: 'reset-password', // This page doesn't even work
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
  {
    path: 'manage-user',
    loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserPageModule),
    canActivate: [AuthGuard, ManageUserGuard],
  },
  {
    path: 'sku',
    loadChildren: () => import('./pages/sku-performance/sku-performance.module').then(m => m.SkuPerformanceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'highlights-floor',
    loadChildren: () => import('./pages/highlights-floor/highlights-floor.module').then(m => m.HighlightsFloorPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'highlights-zone',
    loadChildren: () => import('./pages/highlights-zone/highlights-zone.module').then(m => m.HighlightsZonePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'goal',
    loadChildren: () => import('./pages/goal/goal.module').then(m => m.GoalPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'scorecard-report',
    loadChildren: () => import('./pages/scorecard-report/scorecard-report.module').then(m => m.ScorecardReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox',
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'event-report',
    loadChildren: () => import('./pages/event-report/event-report.module').then(m => m.EventReportPageModule),
    canActivate: [AuthGuard],
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
