<app-updated-warning-header></app-updated-warning-header>
<div *ngIf="showSplash" class="splash">
  <div style="position: absolute">
    <div style="margin-top: -100px; margin-bottom: 60px">
      <div class="deepvision-text">DeepVision</div>
    </div>
    <div>
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
      <div class="circle circle-3"></div>
      <div class="circle circle-4"></div>
    </div>
  </div>
</div>
<div [hidden]="!isLoading" class="loading">
  <div style="position: absolute">
    <div style="text-align: center">
      <div class="loading-circle loading-circle-1"></div>
      <div class="loading-circle loading-circle-2"></div>
    </div>
    <div style="text-align: center">
      <div class="loading-circle loading-circle-3"></div>
      <div class="loading-circle loading-circle-4"></div>
    </div>
    <!-- <div id="loading-text" style="color: var(--ion-color-primary); text-align: center;">Loading {{ loadingText }}</div> -->
    <div *ngIf="errorFetchingMessage" id="loading-text" style="color: var(--ion-color-primary); text-align: center">{{ errorFetchingMessage }}</div>
    <ion-grid *ngIf="errorFetchingMessage && isMobileDevice">
      <ion-row>
        <ion-col></ion-col>
        <ion-col>
          <ion-button class="ion-justify-content-center" (click)="openLocalAppStore()" shape="round">Update</ion-button>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
<div>
  <ion-app>
    <ion-split-pane contentId="menu-content" [when]="shouldDisplayLeftMenu">
      <ion-menu type="overlay" contentId="menu-content" [disabled]="shouldDisableMenu">
        <ion-header>
          <ion-toolbar class="banner-toolbar-container ion-no-padding" style="--background: white">
            <div style="display: flex" (click)="presentOrgSelectorPopover($event)">
              <div style="width: 100%; text-align: center; padding: 4px 8px">
                <img *ngIf="logoImage" style="object-fit: scale-down" [ngStyle]="isOrganization('PLANB') ? { height: '80px' } : { height: '60px' }" [src]="logoImage" />
              </div>
              <div *ngIf="canShowSwitchOrg" class="switch-org-button">
                <ion-icon name="caret-down-circle"></ion-icon>
              </div>
            </div>
          </ion-toolbar>
        </ion-header>
        <ion-content style="--background: var(--ion-menu-background)">
          <ion-list style="background-color: var(--ion-menu-background)">
            <div *ngFor="let menu of navMenu">
              <ion-menu-toggle auto-hide="false">
                <ion-item
                  *ngIf="menu.type !== 'group'"
                  style="margin-top: 10px; --background: var(--ion-menu-background); --color: var(--ion-menu-color)"
                  lines="none"
                  routerDirection="root"
                  [routerLink]="menu.url"
                >
                  <ion-icon style="color: var(--ion-menu-color)" slot="start" [name]="menu.icon"></ion-icon>
                  <ion-label> {{ menu.title }} </ion-label>
                </ion-item>
              </ion-menu-toggle>
              <div class="ripple-parent" *ngIf="menu.type === 'group'" style="margin-top: 10px; position: relative; overflow: hidden">
                <ion-item
                  class="menu-group-button ion-focusable ion-activatable"
                  style="display: flex; flex-flow: row; --background: var(--ion-menu-background); --color: var(--ion-menu-color); cursor: pointer"
                  lines="none"
                  (click)="handleMenuClick(menu)"
                >
                  <ion-ripple-effect></ion-ripple-effect>
                  <ion-icon style="color: var(--ion-menu-color)" slot="start" [name]="menu.icon"></ion-icon>
                  <ion-label> {{ menu.title }} </ion-label>
                </ion-item>
                <ion-list
                  class="ion-no-padding"
                  [ngStyle]="
                    menu.state === 'open' ? { 'max-height': '100rem', flex: 1, transition: 'max-height 0.4s ease-in' } : { 'max-height': '0px', flex: 0, transition: 'max-height 0.4s ease-out' }
                  "
                >
                  <ion-menu-toggle auto-hide="false" *ngFor="let subMenu of menu.child">
                    <ion-item
                      style="--background: var(--ion-menu-background); --color: var(--ion-menu-color); background: var(--ion-menu-background); padding-left: 4em"
                      lines="none"
                      routerDirection="root"
                      [routerLink]="subMenu.url"
                      [queryParams]="subMenu?.queryParams"
                    >
                      <ion-label>{{ subMenu.title }}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-list>
              </div>
            </div>
          </ion-list>
        </ion-content>
        <ion-footer style="background-color: var(--ion-menu-background)">
          <ion-menu-toggle auto-hide="false">
            <ion-item
              *ngIf="!isMobileDevice && canExportRawData"
              style="margin-bottom: 10px; cursor: pointer; --background: var(--ion-menu-background); --color: var(--ion-menu-color)"
              lines="none"
              routerDirection="root"
              routerLink="/export-data"
            >
              <ion-icon style="color: var(--ion-menu-color)" slot="start" name="download"></ion-icon>
              <ion-label> Export Data </ion-label>
            </ion-item>
            <ion-item
              *ngIf="canManageUser"
              style="margin-bottom: 10px; cursor: pointer; --background: var(--ion-menu-background); --color: var(--ion-menu-color)"
              lines="none"
              routerDirection="root"
              routerLink="/manage-user"
            >
              <ion-icon style="color: var(--ion-menu-color)" slot="start" name="people"></ion-icon>
              <ion-label> Manage User </ion-label>
            </ion-item>
            <ion-item
              *ngIf="isOrganization('MBK')"
              style="margin-bottom: 10px; cursor: pointer; --background: var(--ion-menu-background); --color: var(--ion-menu-color)"
              lines="none"
              routerDirection="root"
              routerLink="/settings"
            >
              <ion-icon style="color: var(--ion-menu-color)" slot="start" name="settings"></ion-icon>
              <ion-label> Settings </ion-label>
            </ion-item>
            <ion-item style="margin-bottom: 30px; cursor: pointer; --background: var(--ion-menu-background); --color: var(--ion-menu-color)" lines="none" (click)="onClickLogOut()">
              <ion-icon style="color: var(--ion-menu-color)" slot="start" name="log-out"></ion-icon>
              <ion-label> Sign Out </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-footer>
      </ion-menu>

      <ion-router-outlet id="menu-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</div>
