import { SelectableDataName } from './../objects/selectableData';
import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { GenericBarChartData, GenericPieChartData } from '../objects/chart';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { DynamicBarChartWrapperComponent } from '../shared-components/dynamic/dynamic-bar-chart-wrapper/dynamic-bar-chart-wrapper.component';
import { CHART_PRIMARY_COLOR } from '../configs/color';

export class SimpleBarResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        const biDataGrouper = (prev: any[], current: any, idx: number) => {
            const currentArray = prev[prev.length - 1];
            if (currentArray.length === 2) {
                prev.push([currentArray[currentArray.length - 1]]);
                prev[prev.length - 1].push(current);
            } else {
                prev[prev.length - 1].push(current);
            }
            return prev;
        };
        // load data here
        const datasourceKey = (getAdditionalInput(additionalInput, 'datasourceKey')) as string;
        const postProcessDataKey = (getAdditionalInput(additionalInput, 'postProcessDataKey')) as string;
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey')) as string;
        const xAxesRotation = (getAdditionalInput(additionalInput, 'xAxesRotation')) as number;
        const chartLabel = (getAdditionalInput(additionalInput, 'chartLabel')) as string[] | string;
        graphDataService.baseGraphData.addDependency(GraphDependency[datasourceKey])
        const chartData$ = new BehaviorSubject<GenericBarChartData[]>([]);
        subscription.add(combineLatest(graphDataService[postProcessDataKey])
            .subscribe((data: any[]) => {
                if (data.every(p => p === null)) {
                    return;
                }

                if (chartLabel === "TIME_LIST") {
                    const chartData: GenericBarChartData[] = [{
                        data: data[0],
                        color: CHART_PRIMARY_COLOR,
                        label: 'traffic',
                        barPercentage: 0.8,
                        categoryPercentage: 0.9,
                    }]
                    chartData$.next(chartData);
                    comInstance.chartLabel = configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`);
                } else {
                    const chartData: GenericBarChartData[] = [{
                        data: Object.values(data[0]),
                        color: CHART_PRIMARY_COLOR,
                        label: 'traffic',
                        barPercentage: 0.8,
                        categoryPercentage: 0.9,
                    }]
                    chartData$.next(chartData);
                    comInstance.chartLabel = Object.keys(data[0]).map(k => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[k]);
                }
            }));

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicBarChartWrapperComponent;
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.isLock = this.isLock;
        comInstance.data$ = chartData$;
        comInstance.xAxesRotation = xAxesRotation ?? 0;
        if (chartLabel === "TIME_LIST") {
            comInstance.chartLabel = configDataService.TIME_LIST.reduce(biDataGrouper, [[]]).filter((arr: any[]) => arr.length === 2).map(([t1, t2]) => `${t1}-${t2}`);
        } else if (chartLabel instanceof Array) {
            comInstance.chartLabel = chartLabel.map(k => configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[k]);
        }
        return comInstance;
    }

}
