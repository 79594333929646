import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { DynamicGraphAdditionalInput } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { numberFormatter } from '../helpers/util';
import { SimpleNumberCardComponent } from '../shared-components/dynamic/simple-number-card/simple-number-card.component';

export class SimpleNumberCardResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        _popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription
    ) {
        // load data here
        const datasourceKey = (getAdditionalInput(additionalInput, 'datasourceKey')) as string;
        const datasourceKeys = (getAdditionalInput(additionalInput, 'datasourceKeys')) as string[];
        const postProcessDataKey = (getAdditionalInput(additionalInput, 'postProcessDataKey')) as string;
        const postProcessDataKeys = (getAdditionalInput(additionalInput, 'postProcessDataKeys')) as string[];
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey')) as string;
        const isEnableTBD = (getAdditionalInput(additionalInput, 'isEnableTBD')) as boolean;
        const numberCardLabelType = (getAdditionalInput(additionalInput, 'numberCardLabelType')) as 'none' | 'number' | 'trend';
        const unitLabel = (getAdditionalInput(additionalInput, 'unitLabel')) as 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si';
        const numberData$ = new BehaviorSubject<string>(null);
        const label$ = new BehaviorSubject<string>(null);
        const labelStyle$ = new BehaviorSubject<{ fontSize: string; fontWeight: string; color: string }>(null);
        const labelIcon$ = new BehaviorSubject<string>(null);

        const parseUnit = (value: number, unit: 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si', decimalPoint: number = 0, calculateHour?: boolean,) => {
            if (unit === 'default') {
                if (value >= 0 && value < 1) {
                    return '<1';
                } else {
                    return value.toLocaleString();
                }
            }
            if (value === undefined || value === null) {
                return 'N/A';
            }
            if (unit === 'percent') {
                if (isNaN(value)) {
                    return 'N/A';
                }
                if (value > -1 && value < 1) {
                    return '<1%';
                }
                return value.toFixed(decimalPoint) + '%';
            }
            else if (unit === 'min') {
                if (value < 60) {
                    return '<1 mins';
                }
                return (value / 60).toFixed(0) + ' mins';
            }
            else if (unit === 'hour') {
                if (calculateHour) {
                    return Math.round(value / 3600).toLocaleString() + ' hrs';
                }
                return value.toLocaleString() + ' hrs';
            }
            else if (unit === 'decimal') {
                return value.toFixed(decimalPoint);
            }
            else if (unit === 'si') {
                return numberFormatter(value, 1);
            }
            return value.toLocaleString();
        };

        const parseUnitDiff = (value: number, unit: 'hour' | 'min' | 'percent' | 'default' | 'decimal' | 'si', decimalPoint?: number, calculateHour?: boolean) => {
            if (unit === 'default') {
                if (value >= 0 && value < 1) {
                    return value;
                }
            }
            if (value === undefined || value === null) {
                return 'N/A';
            }
            if (unit === 'percent') {
                if (isNaN(value)) {
                    return 'N/A';
                }
                if (value > -1 || value < 1) {
                    return '<1%';
                }
                return value.toFixed(1) + '%';
            }
            else if (unit === 'min') {
                const minuteVal = (value / 60);
                return minuteVal.toFixed(0);
            }
            else if (unit === 'hour') {
                if (calculateHour) {
                    return Math.round(value / 3600).toLocaleString() + ' hrs';
                }
                return value.toLocaleString();
            }
            else if (unit === 'decimal') {
                if (!decimalPoint) {
                    return value.toFixed(0);
                }
                return value.toFixed(decimalPoint);
            }
            else if (unit === 'si') {
                return numberFormatter(value, 1);
            }
            return value.toLocaleString();
        };

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as SimpleNumberCardComponent;

        if (datasourceKeys) {
            datasourceKeys.forEach(dk => graphDataService.baseGraphData.addDependency(GraphDependency[dk]));
            combineLatest(
                postProcessDataKeys.map(p => graphDataService[p])
            ).subscribe((data: any[]) => {
                // Check if all data are null
                if (data.every(p => p === null)) {
                    return;
                }

                // Handle different label types
                if (numberCardLabelType === 'trend') {
                    const value = data[0][1] - data[1][1]; // Example trend calculation
                    const numberText = parseUnit(value, unitLabel);
                    comInstance.numberData = numberText;
                } else if (numberCardLabelType === 'number') {
                    const value = data[0][1]; // Direct number value
                    const numberText = parseUnit(value, unitLabel);
                    comInstance.numberData = numberText;
                } else {
                    // Default case
                    const value = data[1][1] === 0 ? 0 : (data[0][1] / data[1][1]) * 100;
                    const numberText = parseUnit(value, unitLabel);
                    comInstance.numberData = numberText;
                }
            });
        } else {
            if (postProcessDataKey) {
                graphDataService.baseGraphData.addDependency(GraphDependency[datasourceKey]);
                subscription.add(combineLatest(graphDataService[postProcessDataKey])
                    .subscribe((data: any[]) => {
                        if (data.every(p => p === null)) {
                            return;
                        }
                        if (numberCardLabelType === 'trend') {
                            const numberCardData: { current: number; diff: number; diffPercent: number } = data[0];
                            const numberText = parseUnit(numberCardData.current, unitLabel);
                            const diff = numberCardData.diff;
                            const diffPercent = numberCardData.diffPercent;
                            comInstance.numberData = numberText;
                            comInstance.label = `${parseUnit(diffPercent, 'percent')} (${parseUnitDiff(diff, unitLabel)})`;
                            comInstance.labelStyle = { fontSize: '1rem', fontWeight: '500', color: diff >= 0 ? '#5BBF93' : '#E25144' };
                            comInstance.labelIcon = diff > 0 ? 'trending-up' : 'trending-down';
                        } else if (numberCardLabelType === 'number') {
                            const cardData: { mainContent: string; current: number; } = data[0];
                            if (unitLabel === 'percent') {
                                comInstance.numberData = cardData.mainContent;
                                comInstance.label = `${parseUnit(cardData.current, unitLabel)}`;
                                comInstance.labelStyle = { fontSize: '1rem', fontWeight: '500', color: '#898989' };
                            } else {
                                comInstance.numberData = cardData.mainContent;
                                comInstance.label = `${parseUnit(cardData.current, unitLabel)}`;
                                comInstance.labelStyle = { fontSize: '1rem', fontWeight: '500', color: '#5BBF93' };
                                comInstance.labelIcon = 'people';
                            }
                        } else {
                            const numberCardData: { current: number; diff: number; diffPercent: number } = data[0];
                            const numberText = parseUnit(numberCardData.current, unitLabel);
                            comInstance.numberData = numberText;
                        }

                    }));
            }
        }

        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey] || chartTitleKey;
        comInstance.isLock = this.isLock;
        comInstance.isEnableTBD = isEnableTBD;
        comInstance.height = '150px';
        return comInstance;
    }

}
