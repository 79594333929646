import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-simple-number-card',
  templateUrl: './simple-number-card.component.html',
  styleUrls: ['./simple-number-card.component.scss'],
})
export class SimpleNumberCardComponent implements OnInit, OnChanges {
  @Input() isShow = true;
  @Input() isLock: boolean;
  @Input() title: string;
  @Input() numberData: string;
  @Input() label: string;
  @Input() labelStyle: { fontSize: string; fontWeight: string; color: string };
  @Input() labelIcon: string;
  @Input() iconStyle: string;
  @Input() sizeXS: string;
  @Input() sizeMD: string;
  @Input() offsetMD: string;
  @Input() infoPopover: (e: any) => Promise<any>;
  @Input() isEnableTBD = false;
  @Input() height: string = '100%'

  constructor(
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 500);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.debug(changes);
  }

  ngOnInit() { }

  get parseTitle() {
    return this.title;
  }

}
